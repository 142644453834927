import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import { Logger } from 'app/services/logger.service';

const log = new Logger("AuthGuard");
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;

    // log.debug("route.data => %o",route.data)
    // log.debug("currentUser => %o",currentUser)

    if (currentUser) {
      // log.debug("route?.data?.roles => %o",route?.data?.roles)
      // log.debug("currentUser?.role => %o",currentUser?.role)

      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to not-authorized page

        // this._router.navigate(['/pages/miscellaneous/not-authorized']);
        this._router.navigate(['/pages/miscellaneous/not-permissioned']);
        
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
