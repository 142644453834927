	<div class="switch d-flex justify-content-center align-items-center" >
	    <input 
				(click)="switchLanguage(selectedLang)" 
				[checked]="selectedLang === 'th'"
				id="language-toggle" 
				class="check-toggle check-toggle-round-flat" 
				type="checkbox"
				[disabled]="isLoading"
			>

	    <label for="language-toggle"></label>
	    <span class="on">EN</span>
	    <span class="off">TH</span>
  	</div>