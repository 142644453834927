import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { CoreDirectivesModule } from "@core/directives/directives";

import { TextInputComponent } from "./text";
import { SwitchComponent } from "./switch";
import { SelectComponent } from "./select";
import { ButtonComponent } from "./button";
import { FilesInputComponent } from "./files";
import { DateInputComponent } from "./date";
import { TimeInputComponent } from "./time";
import { CheckBoxComponent } from "./checkbox";
import { TextAreaInputComponent } from "./textarea";
import { MultiSelectComponent } from "./multi-select";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    TextInputComponent,
    SwitchComponent,
    SelectComponent,
    ButtonComponent,
    FilesInputComponent,
    DateInputComponent,
    TimeInputComponent,
    CheckBoxComponent,
    TextAreaInputComponent,
    MultiSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    CoreDirectivesModule,
    NgbTooltipModule,
    TranslateModule
  ],
  exports: [
    TextInputComponent,
    SwitchComponent,
    SelectComponent,
    ButtonComponent,
    FilesInputComponent,
    DateInputComponent,
    TimeInputComponent,
    CheckBoxComponent,
    TextAreaInputComponent,
    MultiSelectComponent,
  ],
})
export class LinxInputModule {}
