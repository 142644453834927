import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { PaginationComponent } from "./pagination";

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, NgbPaginationModule],
  exports: [PaginationComponent],
})
export class LinxPaginationModule {}
