import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FullLoadingIndicatorComponent } from "./full-loading-indicator/full-loading-indicator.component";
import { CarouselImagePreviewComponent } from "./carousel-image-preview/carousel-image-preview.component";
import { AlertLabelComponent } from "./alert-label/alert-label.component";
import { NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";
import { EmptyComponent } from "./empty/empty.component";
import { CoreDirectivesModule } from "@core/directives/directives";
import { LoadingIndicatorComponent } from "./loading-indicator/loading-indicator.component";
import { BeaconPinComponent } from "./beacon-pin/beacon-pin.component";
import { CustomerCardComponent } from "./customer-card/customer-card.component";
import { SortIconComponent } from "./sort-icon/sort-icon.component";
import { MessagePreviewComponent } from "./message-preview/message-preview.component";
import { CorePipesModule } from "@core/pipes/pipes.module";

@NgModule({
  declarations: [
    FullLoadingIndicatorComponent,
    CarouselImagePreviewComponent,
    AlertLabelComponent,
    EmptyComponent,
    LoadingIndicatorComponent,
    BeaconPinComponent,
    CustomerCardComponent,
    SortIconComponent,
    MessagePreviewComponent,
  ],
  imports: [CommonModule, NgbAlertModule, CoreDirectivesModule, CorePipesModule],
  exports: [
    FullLoadingIndicatorComponent,
    CarouselImagePreviewComponent,
    AlertLabelComponent,
    EmptyComponent,
    LoadingIndicatorComponent,
    BeaconPinComponent,
    CustomerCardComponent,
    SortIconComponent,
    MessagePreviewComponent,
  ],
})
export class LinxDisplayModule {}
