import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private currentUserSubject = new BehaviorSubject<any>(null);
  currentUser$ = this.currentUserSubject.asObservable();

  constructor() {
    this.updateCurrentUserFromLocalStorage();
  }

  updateCurrentUserFromLocalStorage() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUserSubject.next(currentUser);
  }
}
