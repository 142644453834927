import { Injectable } from "@angular/core";
import { ItemOption } from "@core/components/linx-input/select";
import { ReceivngFrequencyType } from "app/types/campaign.type";
import { CustomerGroupLabelType } from "app/types/customer-group.type";
import { BackendService } from "./backend.service";
import { Logger } from "./logger.service";

const log = new Logger("OptionsService");

export type DepartmentOption = ItemOption & { floor: string };

@Injectable({
  providedIn: "root",
})
export class OptionsService {
  constructor(private _backendService: BackendService) {}

  async getCustomerGroupOptions() {
    try {
      const queryParams = { limit: 9999, page: 1 };
      const response = await this._backendService.getCustomerGroups(
        queryParams
      );

      const optionResult = response.results;

      const allCustomerOption = optionResult.find(
        (group) => group.name === "all"
      );

      log.debug("getCustomerGroupOptions allCustomerOption => %o",allCustomerOption)

      const options: ItemOption[] = optionResult
        .filter((group) => group.name !== "all")
        .map((group) => ({
          label: group.name,
          value: group.id,
        }));

      if(allCustomerOption){
        options.unshift({
          label: CustomerGroupLabelType.AllCustomers,
          value: allCustomerOption?.id,
        });
      }

      return options;
    } catch (error) {
      throw error;
    }
  }

  async getLocationFloorOptions() {
    try {
      const queryParams = { limit: 9999, page: 1 };
      const response = await this._backendService.getLocationFloor(queryParams);
      const options: ItemOption[] = response.results.map((floor) => ({
        label: floor.name,
        value: floor.id,
      }));
      return options;
    } catch (error) {
      throw error;
    }
  }

  async getLocationDepartmentOptions() {
    try {
      const queryParams = { limit: 9999, page: 1 };
      const response = await this._backendService.getLocationDepartment(
        queryParams
      );
      const options = response.results.map((dep) => ({
        label: `${dep.name} (${dep.locationFloor.name})`,
        value: dep.id,
        floor: dep.locationFloor.id,
      }));

      const _options: DepartmentOption[] = options.filter((opt) => opt.floor);
      return _options;
    } catch (error) {
      throw error;
    }
  }

  async getLocationShopOptions() {
    try {
      const queryParams = { limit: 9999, page: 1 };
      const response = await this._backendService.getLocationShop(queryParams);
      const options: ItemOption[] = response.results.map((shop) => ({
        label: shop.name,
        value: shop.id,
        type: "shop",
        collapsedIcon: "pi pi-caret-right",
        parent: { 
          label: shop.locationDepartment.name,
          value: shop.locationDepartment.id,
          type: "department",
          expandedIcon: "pi pi-folder-open",
          collapsedIcon: "pi pi-folder",
          parent: {
            label: shop.locationFloor.name,
            value: shop.locationFloor.id,
            type: "floor",
            expandedIcon: "pi pi-folder-open",
            collapsedIcon: "pi pi-folder",
          }
        }
      }));
      return options;
    } catch (error) {
      throw error;
    }
  }

  async getQuestionnaireOptions() {
    try {
      const queryParams = { limit: 9999 };
      const response = await this._backendService.getQuestionnaire(queryParams);
      const options: ItemOption[] = response.results.map((question) => ({
        label: question.basicInfo.name,
        value: question.id,
      }));
      return options;
    } catch (error) {
      throw error;
    }
  }

  async getLocationOptions() {
    try {
      const queryParams = { limit: 9999 };
      const response = await this._backendService.getLocationShop(queryParams);
      const options: ItemOption[] = response.results.map((location) => ({
        label: `${location.locationFloor?.name} | ${location.locationDepartment?.name} | ${location.name}`,
        value: location.id,
      }));
      return options;
    } catch (error) {
      throw error;
    }
  }

  async getActiveDeviceOptions() {
    try {
      const queryParams = { limit: 9999, enabled: "true" };
      const response = await this._backendService.getDevices(queryParams);
      const options: ItemOption[] = response.results.map((device) => ({
        label: `${device?.name} ${
          !!device?.locationShop?.name ? `(${device.locationShop?.name})` : ""
        }`,
        value: device.id,
      }));
      return options;
    } catch (error) {
      throw error;
    }
  }

  async getOrganizeOptions() {
    try {
      const queryParams = { limit: 9999 };
      const response = await this._backendService.getOrganizes(queryParams);
      const options: ItemOption[] = response.results.map((organize) => ({
        label: organize.name,
        value: organize.id,
      }));
      return options;
    } catch (error) {
      throw error;
    }
  }

  getReceivngFrequencyOptions() {
    const receivngFrequencyOptions: ItemOption[] = [
      {
        label: ReceivngFrequencyType.Every10Mins.label,
        value: ReceivngFrequencyType.Every10Mins.value,
      },
      {
        label: ReceivngFrequencyType.Every30Mins.label,
        value: ReceivngFrequencyType.Every30Mins.value,
      },
      {
        label: ReceivngFrequencyType.Every1Hour.label,
        value: ReceivngFrequencyType.Every1Hour.value,
      },
      {
        label: ReceivngFrequencyType.Every6Hours.label,
        value: ReceivngFrequencyType.Every6Hours.value,
      },
      {
        label: ReceivngFrequencyType.OnceADay.label,
        value: ReceivngFrequencyType.OnceADay.value,
      },
      {
        label: ReceivngFrequencyType.Every3Days.label,
        value: ReceivngFrequencyType.Every3Days.value,
      },
      {
        label: ReceivngFrequencyType.OnceAWeek.label,
        value: ReceivngFrequencyType.OnceAWeek.value,
      },
      {
        label: ReceivngFrequencyType.OnceAMonth.label,
        value: ReceivngFrequencyType.OnceAMonth.value,
      },
      {
        label: ReceivngFrequencyType.OnceACampaign.label,
        value: ReceivngFrequencyType.OnceACampaign.value,
      },
    ];

    return receivngFrequencyOptions;
  }
}
