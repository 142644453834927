export const locale = {
  lang: 'th',
  data: {
    MENU: {
      APPS: {
        SECTION: 'แอป และเพจ',
        EMAIL: 'อีเมล',
        CHAT: 'แชท',
        TODO: 'สิ่งที่ต้องทำ',
        CALENDAR: 'ปฏิทิน',
        INVOICE: {
          COLLAPSIBLE: 'ใบแจ้งหนี้',
          LIST: 'รายการ',
          PREVIEW: 'ดูตัวอย่าง',
          EDIT: 'แก้ไข',
          ADD: 'เพิ่ม',
        },
        ECOMMERCE: {
          COLLAPSIBLE: 'อีคอมเมิร์ซ',
          SHOP: 'ร้านค้า',
          DETAIL: 'รายละเอียด',
          WISHLIST: 'รายการสิ่งที่ต้องการ',
          CHECKOUT: 'ชำระเงิน',
        },
        USER: {
          COLLAPSIBLE: 'ผู้ใช้',
          LIST: 'รายการ',
          VIEW: 'ดู',
          EDIT: 'แก้ไข',
        },
      },
      ASSETS: {
        NAME: 'ทรัพยากร',
        BEACONS: 'จุดสัญญาณของคุณ',
        LOCATIONS: 'ตำแหน่ง',
        CUSTOMERS: 'ฐานข้อมูลลูกค้า',
        SECTION: 'ทรัพยากร',
        HEATMAP: 'แผนภูมิความร้อน',
        CUSTOMER_GROUPS: 'กลุ่มลูกค้า',
        ATTENDANCE_REPORT: 'รายงานการเข้าร่วม',
        REPORTS: 'รายงาน',
      },
      CM: {
        SECTION: 'แผนภูมิ และแผนที่',
        CHARTS: {
          COLLAPSIBLE: 'แผนภูมิ',
          BADGE: '2',
          APEX: 'Apex',
          CHARTJS: 'ChartJS',
        },
        MAPS: 'Google Maps',
      },
      DASHBOARD: {
        COLLAPSIBLE: 'แดชบอร์ด',
        BADGE: '2',
        ANALYTICS: 'วิเคราะห์',
        ECOMMERCE: 'อีคอมเมิร์ซ',
        NAME: 'แดชบอร์ด',
        SECTION: 'ข้อมูล',
        HEATMAP: 'แผนภูมิความร้อน',
        ACTIVITIES: 'กิจกรรมของลูกค้า',
      },
      FT: {
        SECTION: 'แบบฟอร์ม และตาราง',
        ELEMENT: {
          COLLAPSIBLE: 'องค์ประกอบของฟอร์ม',
          INPUT: 'อินพุต',
          INPUTGROUPS: 'กลุ่มอินพุต',
          INPUTMASK: 'มาสก์อินพุต',
          TEXTAREA: 'เท็กซ์เอเรีย',
          CHECKBOX: 'ช่องเลือก',
          RADIO: 'ราดิโอ',
          SWITCH: 'สวิตช์',
          SELECT: 'เลือก',
          NUMBERINPUT: 'อินพุตตัวเลข',
          FILEUPLOADER: 'เครื่องมืออัปโหลดไฟล์',
          QUILLEDITOR: 'Quill Editor',
          FLATPICKER: 'Flatpicker',
          DATETIMEPICKER: 'เครื่องมือเลือกวันและเวลา',
        },
        LAYOUTS: 'เค้าโครงของฟอร์ม',
        WIZARD: 'ตัวช่วยฟอร์ม',
        VALIDATION: 'การตรวจสอบฟอร์ม',
        REPEATER: 'เครื่องมือเพิ่มรายการในฟอร์ม',
        TABLE: 'ตาราง',
        DATATABLES: 'ตารางข้อมูล',
      },
      MARKETING: {
        NAME: 'การตลาด',
        SECTION: 'การตลาด',
        CAMPAIGN: 'แคมเปญ',
        BROADCAST: 'บรอดแคสต์',
        INSIGHT: 'ความหนาแน่นของผู้เยี่ยมชม',
        QUESTIONNAIRE: 'แบบสอบถาม',
        COUPON: 'คูปอง',
        AUTO_RESPONSE_MESSAGE: 'ข้อความตอบกลับอัตโนมัติ',
        MESSAGES: 'ข้อความ',
      },
      OTHER: {
        LOGS: 'บันทึกของอุปกรณ์',
        USERS_MANAGEMENT: 'การจัดการผู้ใช้',
        SETTINGS: 'การตั้งค่า',
        SECTION: 'อื่น ๆ',
      },
      OTHERS: {
        SECTION: 'อื่น ๆ',
        LEVELS: {
          COLLAPSIBLE: 'ระดับเมนู',
          SECOND: 'ระดับที่สอง',
          SECOND1: {
            COLLAPSIBLE: 'ระดับที่สอง',
            THIRD: 'ระดับที่สาม',
            THIRD1: 'ระดับที่สาม',
          },
        },
        DISABLED: 'เมนูที่ปิดใช้งาน',
        DOCUMENTATION: 'เอกสาร',
        SUPPORT: 'แจ้งปัญหา',
      },
      PAGES: {
        SECTION: 'หน้า',
        AUTH: {
          COLLAPSIBLE: 'การพิสูจน์ตัวตน',
          LOGIN1: 'เข้าสู่ระบบ V1',
          LOGIN2: 'เข้าสู่ระบบ V2',
          REGISTER1: 'ลงทะเบียน V1',
          REGISTER2: 'ลงทะเบียน V2',
          FORGOTPASSWORD1: 'ลืมรหัสผ่าน V1',
          FORGOTPASSWORD2: 'ลืมรหัสผ่าน V2',
          RESETPASSWORD1: 'รีเซ็ตรหัสผ่าน V1',
          RESETPASSWORD2: 'รีเซ็ตรหัสผ่าน V2',
        },
        ACCOUNTSETTINGS: 'การตั้งค่าบัญชี',
        PROFILE: 'โปรไฟล์',
        FAQ: 'คำถามที่พบบ่อย',
        KB: 'ฐานความรู้',
        PRICING: 'ราคา',
        BLOG: {
          COLLAPSIBLE: 'บล็อก',
          LIST: 'รายการ',
          DETAILS: 'รายละเอียด',
          EDIT: 'แก้ไข',
        },
        MAIL: {
          COLLAPSIBLE: 'เทมเพลตอีเมล',
          WELCOME: 'ยินดีต้อนรับ',
          RESET: 'รีเซ็ตรหัสผ่าน',
          VERIFY: 'ยืนยัน',
          DEACTIVATE: 'ปิดการใช้งาน',
          INVOICE: 'ใบแจ้งหนี้',
          PROMOTIONAL: 'โปรโมชั่น',
        },
        MISC: {
          COLLAPSIBLE: 'เรื่องราวอื่น ๆ',
          COMINGSOON: 'เร็ว ๆ นี้',
          NOTAUTH: 'ไม่ได้รับอนุญาต',
          MAINTENANCE: 'กำลังบำรุงรักษา',
          ERROR: 'ข้อผิดพลาด',
        },
      },
      REPORTS: {
        FUNNEL: 'การวิเคราะห์ทางการขาย'
      },
      UI: {
        SECTION: 'อินเตอร์เฟซผู้ใช้',
        TYPOGRAPHY: 'ตัวอักษร',
        COLORS: 'สี',
        FEATHER: 'Feather',
        CARDS: {
          COLLAPSIBLE: 'การ์ด',
          BADGE: 'ใหม่',
          BASIC: 'พื้นฐาน',
          ADVANCE: 'ขั้นสูง',
          STATISTICS: 'สถิติ',
          ANALYTICS: 'วิเคราะห์',
          ACTIONS: 'การดำเนินการ',
        },
        COMPONENTS: {
          COLLAPSIBLE: 'คอมโพเนนต์',
          ALERTS: 'การแจ้งเตือน',
          AVATAR: 'อวาตาร์',
          BADGES: 'ป้าย',
          BREADCRUMBS: 'เส้นทาง',
          BUTTONS: 'ปุ่ม',
          CAROUSEL: 'การ์เซล',
          COLLAPSE: 'ย่อหน้า',
          DIVIDER: 'แยกส่วน',
          DROPDOWNS: 'เมนูแบบเลื่อนลง',
          GROUP: 'กลุ่มรายการ',
          OBJECTS: 'วัตถุมัลติมีเดีย',
          MODALS: 'โมดัล',
          COMPONENT: 'เมนู',
          PAGINATION: 'การแบ่งหน้า',
          PBADGES: 'ป้ายพิล',
          PILLS: 'พิล',
          POPOVERS: 'พ็อพอเวอร์',
          PROGRESS: 'แถบความคืบหน้า',
          RATINGS: 'คะแนน',
          SPINNER: 'สปินเนอร์',
          TABS: 'แท็บ',
          TIMELINE: 'ไทม์ไลน์',
          TOASTS: 'แจ้งเตือน',
          TOOLTIPS: 'ทูลทิป',
        },
        EX: {
          COLLAPSIBLE: 'ส่วนขยาย',
          SWEET_ALERTS: 'Sweet Alerts',
          BLOCKUI: 'BlockUI',
          TOASTER: 'Toastr',
          SLIDER: 'สไลเดอร์',
          DRAGDROP: 'ลากและวาง',
          TOUR: 'การเทียบเทียม',
          CLIPBOARD: 'คลิปบอร์ด',
          MEDIAPLAYER: 'เครื่องเล่นมีเดีย',
          CONTEXTMENU: 'เมนูคอนเท็กซ์ตัวเลือก',
          SWIPER: 'Swiper',
          TREEVIEW: 'มุมมองต้นไม้',
          I18N: 'I18n',
        },
        LAYOUTS: {
          COLLAPSIBLE: 'เค้าโครงหน้า',
          COLLAPSED_MENU: 'เมนูที่ยุบ',
          BOXED_LAYOUT: 'เค้าโครงแบบกล่อง',
          WITHOUT_MENU: 'โดยไม่มีเมนู',
          LAYOUT_EMPTY: 'เค้าโครงเปล่า',
          LAYOUT_BLANK: 'เค้าโครงว่างเปล่า',
        },
      },
    },

    // Dashboard
    DASHBOARD:{
      VISITOR_DATA: 'ข้อมูลผู้เยี่ยมชม',
      RAW_DATA: 'ข้อมูลเบื้องต้น',
      FOR_CROSS_CHECK: 'เพื่อตรวจสอบความถูกต้อง (เฉพาะในระยะการพัฒนา)',
      DATE: 'วันที่',
      NEW_VISITOR: 'ผู้เยี่ยมชมใหม่',
      ALL_VISITOR: 'ผู้เยี่ยมชมทั้งหมด',
      UNIQUE_VISITOR: 'ผู้เยี่ยมชมที่ไม่ซ้ำ',
      RE_VISITOR: 'ผู้เยี่ยมชมอีกครั้ง',
      REVISITING_REPORT: 'รายงานการเยี่ยมชมอีกครั้ง',
      REVISIT: 'เยี่ยมชมอีกครั้ง',
      IDLE: 'ไม่ได้ใช้งาน',
      ONE_WEEK: 'อาทิตย์',
      TWO_WEEK: 'อาทิตย์',
      AVERAGE: 'ค่าเฉลี่ย',
      VIEW_CUSTOMER_ACTIVITIES: 'ดูกิจกรรมของลูกค้า',
      TOP_ZONES: 'โซนยอดนิยม',
      THE_MOST_POPULAR_AREAS: 'พื้นที่ยอดนิยมที่ลูกค้าผ่าน และใช้เวลาเฉลี่ยใน 24 ชั่วโมง',
      ZONE: 'โซน',
      DEPARTMENT: 'แผนก',
      UNIQUE_VISITORS: 'ผู้เยี่ยมชมที่ไม่ซ้ำ',
      TIME_SPEND: 'เวลาที่ใช้',
      UNIQUE: 'ไม่ซ้ำกัน',
      LOWEST_ZONES: 'ไม่เป็นที่นิยม',
      THE_LESS_POPULAR_AREAS: 'พื้นที่ที่รับความนิยมน้อยที่สุดที่ลูกค้าเดินผ่าน และใช้เวลาเฉลี่ยใน 24 ชั่วโมง',
      CAMPAIGN_REDEMPTION: 'แคมเปญแลกของรางวัล',
      THIS_MONTH: 'เดือนนี้',
      COUPONS_REDEEMED: 'คูปองที่ถูกแลกแล้ว',
      VIEW_ALL_CAMPAIGNS: 'ดูแคมเปญทั้งหมด',
      VISITOR_S: 'ผู้เยี่ยมชม',
    },

    // Heatmap
    HEATMAP: {
      HEAT_MAP: 'แผนที่ความร้อน',
      DISPLAYS_THE_LOCATION: 'แสดงตำแหน่งของบีคอนที่ติดตั้งในแต่ละชั้น และข้อมูลที่รวบรวมในช่วงเวลาที่คุณต้องการทราบ',
      DATE_RANGE: 'ช่วงวันที่',
      TOTAL_VISITOR: 'ผู้เข้าชมทั้งหมด',
      UNIQUE_VISITOR: 'ผู้เข้าชมที่ไม่ซ้ำกัน',
      HARDWARE_ID: 'รหัสฮาร์ดแวร์',
      NAME: 'ชื่อ',
      SHOP: 'ร้านค้า',
      STATUS: 'สถานะ',
      FLOOR: 'ชั้น',
      SELECT_FLOOR: 'เลือกชั้น',

    },
  
    // Customer Activities
    CUSTOMER_ACTIVITIES: {
      CUSTOMER_ACTIVITIES: 'กิจกรรมลูกค้า',
      LOADING: 'กำลังโหลด...',
      LOADING_SUCCESS: 'สำเร็จ',
      CHECK_THE_STATUS: 'ตรวจสอบสถานะของลูกค้ารายบุคคล ในแคมเปญและช่วงเวลาที่ต้องการทราบ',
      SELECT_CAMPAIGN: 'เลือกแคมเปญ',
      SELECT_CUSTOMER_TYPE: 'เลือกประเภทลูกค้า',
      SELECT_CUSTOMER_GROUP: 'เลือกกลุ่มลูกค้า',
      SELECT_CUSTOMER: 'เลือกกลุ่มลูกค้า',
      DATE_RANGE: 'ช่วงวันที่',
      SEARCH: 'ค้นหา',
      RESULT: 'ผลลัพธ์',
      ACTIVITIES_IN_RESULT: 'กิจกรรมในผลลัพธ์',
      EVENT: 'อีเวนต์',
      CUSTOMER: 'ลูกค้า',
      ON_DATE: 'เมื่อวันที่',
      DATA_SAVED: 'บันทึกข้อมูลเรียบร้อย',
    },
    
    // Visitor Density
    VISITOR_DENSITY: {
      VISITOR_DENSITY: 'ความหนาแน่นของผู้เข้าชม',
      YOU_CAN_CHECK: 'คุณสามารถตรวจสอบความหนาแน่นของลูกค้าที่เข้ามา ในช่วงเวลาที่คุณต้องการทราบ',
      DATE_RANGE: 'ช่วงวันที่',
      DEVICES_ONLY_ENABLED_DEVICES: 'อุปกรณ์ (เฉพาะที่เปิดใช้งาน)',
      DEVICES: 'เลือกอุปกรณ์',
      VISITORS: 'ผู้เข้าชม',
      UNIQUE_VISITORS: 'ผู้เยี่ยมชมที่ไม่ซ้้ำ',
      TOTAL_VISITORS: 'ผู้เข้าชมทั้งหมด',
    },

    // Message list
    MESSAGES: {
      MESSAGE_TEMPLATES: 'เทมเพลตข้อความ',
      MESSAGE_TEMPLATES_FOR_QUICK: 'เทมเพลตข้อความ ที่ใช้สร้างแคมเปญอย่างรวดเร็ว *เคล็ดลับ: ข้อความรูปภาพ เป็นที่ดึงดูดความสนใจของลูกค้าได้อย่างยอดเยี่ยม',
      TYPE: "ประเภท",
      SEARCH_TEMPLATE_TYPE: "ค้นหาประเภทของเทมเพลต",
      SEARCH: "ค้นหา",
      SEARCH_TEMPLATE_NAME: "ค้นหาชื่อเทมเพลต",
      NAME: "ชื่อ",
      MESSAGE: "ข้อความ",
      CREATED_AT: "สร้างเมื่อ",
      UPDATED_AT: "อัปเดตเมื่อ",
      TOTAL: "(ทั้งหมด)",
      TOTAL_ANSWERS: 'จำนวนคำตอบทั้งหมด:',
      EDIT: "แก้ไข",
      DELETE: "ลบ",
      BACK: 'กลับ',
      TEXT: 'ข้อความ',
      IMAGE: 'รูปภาพ',
      IMAGE_MAP: 'แผนที่รูปภาพ',
      IMAGEMAP: 'แผนที่รูปภาพ',
      COUPON: 'คูปอง',
      CAROUSEL: 'การ์ด',
      CARD: 'การ์ด',
      QUESTIONNAIRE: 'แบบสอบถาม',
      FLEX: 'เฟลค',
      ADD_NEW: 'เพิ่มใหม่',
      ALL: 'ทั้งหมด',
      ENTER_NAME: 'ใส่ชื่อ',
      TEXT_MESSAGE: 'ข้อความ',
      ENTER_TEXT_MESSAGE: 'ใส่ข้อความ',
      SAVE: 'บันทึก',
      IMAGE_MESSAGE: 'ข้อความรูปภาพ',
      UPLOAD_IMAGE: 'อัปโหลดรูปภาพ',
      FORMAT: 'ฟอร์แมต: JPEG, PNG',
      SIZE: 'ขนาด: สูงสุด ',
      ALT_TEXT: 'ข้อความแสดงแทน',
      ENTER_ALT_TEXT: 'ใส่ข้อความแสดงแทน',
      URI: 'ลิ้งค์',
      ENTER_URI: 'ใส่ลิ้งค์ที่ต้องการ',
      DIMENSIONS: 'ขนาดรูป: ',
      TITLE: 'ชื่อเรื่อง',
      ENTER_TITLE: 'ใส่ชื่อเรื่อง',
      DELETE_CARD: 'ลบการ์ด',
      UPLOAD: 'อัปโหลด',
      DESCRIPTION: 'คำอธิบาย',
      ENTER_DESCRIPTION: 'ใส่คำอธิบาย',
      IMAGE_BACKGROUND_COLOR: 'สีพื้นหลังของรูปภาพ',
      ACTIONS: 'การทำงานของการ์ด',
      ADD_ACTIONS: 'เพิ่มการทำงานของการ์ด',
      ENTER_ACTIONS: 'รูปแบบ',
      LABEL: 'ป้ายกำกับ',
      ENTER_LABEL: 'ใส่ป้ายกำกับ',
      MESSAGES: 'ข้อความ',
      ENTER_MESSAGES: 'ใส่ข้อความ',
      ENTER_QUESTIONNAIRE: 'เลือกแบบสอบถาม',
      FLEX_MESSAGE: 'ข้อความแบบเฟลค',
      ENTER_FLEX_MESSAGE: 'ใส่ข้อความแบบเฟลค',
    },
    SETTING:{
      LOADING:'กำลังโหลด',
      SUCCESS:'สำเร็จ',
      SETTING:'การตั้งค่า',
      NAME:'ชื่อ',
      LOGO:'โลโก้',
      UPLOAD_IMAGE:'อัปโหลดรูปภาพ',
      REGISTER_DETAIL_CONFIG: 'กำหนดรายละเอียดการลงทะเบียน',
      LINE_CONFIG:'กำหนดค่าไลน์',
      INFO_SETTING:'ตั้งค่าข้อมูล',
      LINE_HOOK_URL:'ลิ้งค์ไลน์ฮุค',
      SAVE:'บันทึก',
      FORCE_CONFIG_OPTION: 'การกำหนดตัวเลือกแบบบังคับ',
      PREFIX:'คำนำหน้า (ค่าเริ่มต้น)',
      FIRSTNAME:'ชื่อ (ค่าเริ่มต้น)',
      LASTNAME: 'นามสกุล (ค่าเริ่มต้น)',
      GENDER: 'เพศ (ค่าเริ่มต้น)',
      AGE: 'อายุ (ค่าเริ่มต้น)',
      ID_CARD: 'เลขบัตรประจำตัวประชาชน',
      MOBILE_PHONE:'เบอร์โทรศัพท์',
      EMAIL:'อีเมลล์',
      TEXTSPECIFICATION: 'ข้อมูลจำเพาะ',
      PDPA: 'กฎหมาย PDPA',
      POLICY: 'นโยบาย',
      LINE_BOT: 'ไลน์บอท',
      CHANNEL_ID:'แชแนล ID',
      CHANNEL_ACCESS_TOKEN:'แชแนล Access Token',
      CHANNEL_SECRET:"แชแนล Secret",
      LINE_LOGIN:'เข้าสู่ระบบด้วยไลน์',
      CHANNEL_ID_REQUIRED:'ต้องระบุ Channel Id',
      CHANNEL_ACCESS_TOKEN_REQUIRED:'ต้องระบุ Channel Access Token',
      CHANNEL_SECRET_REQUIRED:'ต้องระบุ Channel Secret',
      LIFF_ID_REQUIRED:'ต้องระบุ liff ID',
      LIFF_URL_REQUIRED:'ต้องระบุ liff Url',
      SAVE_CHANGE:'บันทึกการเปลี่ยนแปลง',
      FORMAT:'รูปแบบ: JPEG, PNG',
      SIZE:'ขนาด: สูงสุด ',
      DIMENSION:'ขนาด: ',
      REF_CODE: 'รหัสอ้างอิง',
      //setting.title | 'แปล'
    },
    DEVICELOG:{
      LOADING:'กำลังโหลด',
      DEVICELOG:'บันทึกอุปกรณ์',
      SELECTDEVICE:'เลือกอุปกรณ์',
      DATARANGE:'ช่วงวันที่',
      SEARCH:'ค้นหา',
    },
    USERMANAGEMENT:{
      USERMANAGEMENT:'การจัดการผู้ใช้',
      ADDNEW:'เพิ่มใหม่',
      SEARCH:'ค้นหา',
      SEARCHNAME:'ค้นหาชื่อ',
      NAME:'ชื่อ',
      ORGANIZE:'องค์กร',
      ROLE:'บทบาท',
      EMAIL:'อีเมลล์',
      CREATEDAT:'สร้างเมื่อ',
      UPDATEDAT:'อัปเดทเมื่อ',
      TOTAL:'ทั้งหมด',
      EDIT: 'แก้ไข',
      CHANGE_PASSWORD:'เปลี่ยนรหัสผ่าน',
      DELETE:'ลบ',
      BACK:'ย้อนกลับ',
      EMAILFORM:'อีเมล',
      PASSWORDFORM:'รหัสผ่าน',
      PASSWORDCONFIRM:'ยืนยันรหัสผ่าน',
      NAMEFORM:'ชื่อ',
      ROLEFORM:'บทบาท',
      ORGANIZEFORM:'องค์กร',
      ACTIONFORM:'หน้าที่',
      ADD_USER_ROLE : 'เพิ่มบทบาทของผู้ใช้',
      SAVE:'บันทึก',
      ADD:"เพิ่ม",
      DATAUPDATED:'ข้อมูลอัปเดท',
      PASSWORDUPDATED:'อัปเดทรหัสผ่าน',
      MUST_BE_AT_LEAST_WORD : 'ต้องมีความยาวอย่างน้อย 8 หลัก',
      SUCCESS:'สำเร็จ',

    },
    FUNNEL:{
      FUNNELANALYTIC:'การวิเคราะห์ช่องทาง',
      UNDERSTANDING:'ทำความเข้าใจขั้นตอนที่จำเป็นในการเข้าถึงผลลัพธ์ของโฟลว์และจำนวนผู้ใช้ที่ผ่านแต่ละขั้นตอนเหล่านั้น',
      SELECTCAMPAIGN:'เลือกแคมเปญ',
      DATERANGE:'ช่วงวันที่',
      SEARCH:'ค้นหา',
      RESULT:'ผลลัพธ์',
      LOADING:'กำลังโหลด',
      TOTAL_CUSTOMER:'ลูกค้าทั้งหมด',
      IDLE:'ไม่ได้ใช้งาน',
      ENTER:'ใช้งาน',
      SEND:'ส่ง',
      CLICKED:'คลิกแล้ว',
      ALL_CUSTOMER:'ลูกค้าทั้งหมด',
    },
    ATTENDANCE:{
      ATTENDANCE:'รายงานการเข้าร่วม',
      CUSTOMER_GROUP:'กลุ่มลูกค้า',
      DATE:'วันที่',
      TOTAL:'ทั้งหมด',
    },
    CUSTOMERS:{
      CUSTOMER:'ลูกค้า',
      ALLCUSTOMER: 'ลูกค้าทุกคนในระบบ',
      REGISTER_STATUS:'สถานะการลงทะเบียน',
      SEARCH:'ค้นหา',
      SEARCHANOTHER : 'ค้นหา ชื่อ, เบอร์โทรศัพท์, อีเมล, อายุ',
      AGE:'อายุ',
      LINE_USER_ID:'ไอดีผู้ใช้ไลน์',
      CREATED_AT:'สร้างเมื่อ',
      REGISTERED: 'ลงทะเบียนแล้ว',
      UNREGISTERED: 'ไม่ได้ลงทะเบียน',
      TOTAL:'ทั้งหมด',
      LOADING:'กำลังโหลด',
    },
    CUSTOMERTYPE:{
      REGISTERED:'ลงทะเบียนแล้ว',
      UNREGISTERED:'ไม่ได้ลงทะเบียน',
    },
    CUSTOMERGROUP:{
      CUSTOMER_GROUP:'กลุ่มลูกค้า',
      GROUP_DESCRIPTION:'กลุ่มของผู้ใช้ที่สร้างขึ้นโดยการระบุคุณสมบัติและเงื่อนไขของผู้ใช้ เมื่อคุณกำหนดผู้ชมของคุณแล้ว คุณสามารถส่งข้อความไปยังกลุ่มผู้ใช้ที่มีคุณสมบัติตามที่ต้องการได้',
      ADD_NEW:'เพิ่มใหม่',
      SEARCH:'ค้นหา',
      SEARCH_NAME:'ค้นหาชื่อ',
      NAME:'ชื่อ',
      AMOUNT:'จำนวน',
      DETAIL:'รายละเอียด',
      EDIT:'แก้ไข',
      DELETE:'ลบ',
      CREATE_AT:'สร้างเมื่อ',
      BACK:'ย้อนกลับ',
      GROUP_NAME:'ชื่อกลุ่ม',
      CUSTOMER_IN_GROUP:'ลูกค้าในกลุ่ม',
      MOBILE:'เบอร์โทรศัพท์',
      EMAIL:'อีเมล',
      AUTOUPDATE:'อัปเดทอัตโนมัติ',
      CUSTOMERS:'ลูกค้า',
      SEARCH_CUSTOMERS:'ค้นหาลูกค้า',
      PLEASE_ENTER:'กรุณาใส่ตัวอักษรตั้งแต่ 2 ตัวขึ้นไป',
      STANDARD_QUESTION:'คำถามมาตรฐาน',
      MULTIPLE_QUESTION:'คำถามหลายคำตอบ',
      NEWQUESTION:'คำถามใหม่',
      SELECT_QUESTION:"เลือกคำถาม",
      STATE:"สถานะ",
      ADD_NEW_QUESTION:"เพิ่มคำถามใหม่",
      TARGET_ESTIMATE:"กลุ่มเป้าหมายประมาณ",
      AMOUNT_CUSTOMER_IN_GROUP:"จำนวนลูกค้าในกลุ่ม",
      FROM:"จากทั้งหมด",
      CALCULATE_AS:"คำนวนเป็น",
      TOTAL:"จากทั้งหมด",
      UNIT:"คน",
      SAVE:"บันทึก",
      // MALE:'ผู้ชาย',
      // FEMALE:'ผู้หญิง',
      // YEARS1:'20 - 24 ปี',
      // YEARS2:'25 - 29 ปี',
      // YEARS3:'30 - 34 ปี',
      // YEARS4:'35 - 39 ปี',
      // YEARS5:'40 - 44 ปี',
      // YEARS6:'45 - 49 ปี',
      // YEARS7:'50 ปี หรือ สูงกว่า'

    },
    LOCATION:{
      LOCATION:'ตำแหน่ง',
      THE_WHOLE_FLOOR:'แผนผังชั้นทั้งหมดของอาคารสำหรับวิเคราะห์ข้อมูลการเดินทางของลูกค้า',
      SEARCH_FLOOR:'ค้นหาชั้น',
      FLOOR:'ชั้น',
      CREATED_AT:'สร้างเมื่อ',
      TOTAL:'ทั้งหมด',
      DELETE:'ลบ',
      LOADING:'กำลังโหลด',
      CONFIRM_DELETE:'ยืนยันการลบ',
      CONFIRM_DELETE_FLOOR:'ยืนยันการลบชั้น',
      CONFIRM_DELETE_DEPARTMENT:'ยืนยันการลบแผนก',
      CONFIRM_DELETE_SHOP:'ยืนยันการลบร้าน',
      BACK:'ย้อนกลับ',
      EDIT:'แก้ไข',
      CANCEL:'ยกเลิก',
      CLOSE:'ปิด',
      DEPARTMENT:'แผนก',
      DO_YOU_CONFIRM1:'คุณต้องการที่จะลบข้อมูลของ',
      DO_YOU_CONFIRM2:'?',
      ADD_NEWDEPARTMENT_FORM:'เพิ่มแผนกใหม่',
      DEPARTMENT_FORM:'แผนก',
    },
    
    // Campaigns
    CAMPAIGNS: {
      CAMPAIGNS: 'แคมเปญ',
      YOU_CAN_AUTOMATICALLY_SEND: 'คุณสามารถส่งข้อความถึงผู้ใช้ที่เป็นเพื่อนกับบัญชีทางการได้โดยอัตโนมัติ ตามเงื่อนไขที่คุณต้องการ',
      ADD_NEW: 'เพิ่มใหม่',
      SEARCH: 'ค้นหา',
      SEARCH_CAMPAIGN_NAME: 'ค้นหาชื่อแคมเปญ',
      NAME: 'ชื่อ',
      PERIOD: 'ระยะเวลา',
      PRIORITY: 'ลำดับความสำคัญ',
      CREATED_AT: "สร้างเมื่อ",
      UPDATED_AT: "อัปเดตเมื่อ",
      TOTAL: "(ทั้งหมด)",
      DETAIL: 'รายละเอียด',
      EDIT: 'แก้ไข',
      DELETE: 'ลบ',
      CONFIRM_DELETION: 'ยืนยันการลบ',
      CANCEL: 'ยกเลิก',
      WARNING: 'คำเตือน !!',
      YOU_DONT_HAVE_LOCATIONS_AND_CUSTOMER_GROUPS: 'คุณไม่มี ตำแหน่ง และ กลุ่มลูกค้า',
      OK: 'ตกลง',
      BASIC_INFORMATION: 'ข้อมูลพื้นฐาน',
      ENTER_NAME: 'ใส่ชื่อ',
      CAMPAIGN_PERIOD: 'ระยะเวลาของแคมเปญ',
      START_DATE: 'วันที่เริ่มต้น',
      END_DATE: 'วันที่สิ้นสุด',
      LOCATION: 'ตำแหน่ง',
      SELECT_SHOP: 'เลือกร้านค้า',
      SELECT_A_LEVEL: 'เลือกระดับความสำคัญ',
      ALWAYS: 'สม่ำเสมอ',
      SPECIFIC_TIME: 'เวลาที่เจาะจง',
      RECEIVING_FREQUENCY: 'ความถี่ในการรับ',
      ENTER_RECEIVING_FREQUENCY: 'เลือกความถี่ในการรับ',
      BROADCAST: 'บรอดแคสต์',
      CUSTOMER_GROUP: 'กลุ่มลูกค้า',
      ENTER_CUSTOMER_GROUP: 'เลือกกลุ่มลูกค้า',
      PRIORITY_lower: 'ลำดับความสำคัญ',
      PRIORITY_info: 'ความสำคัญ',
      HIGH: 'สูง',
      MEDIUM: 'ปานกลาง',
      NORMAL: 'ปกติ',
      SUNDAY: 'วันอาทิตย์',
      MONDAY: 'วันจันทร์',
      TUESDAY: 'วันอังคาร',
      WEDNESDAY: 'วันพุธ',
      THURSDAY: 'วันพฤหัสบดี',
      FRIDAY: 'วันศุกร์',
      SATURDAY: 'วันเสาร์',
      _10m: 'ทุกๆ 10 นาที',
      _30m: 'ทุกๆ 30 นาที',
      _1h: 'ทุกชั่วโมง',
      _6h: 'ทุกๆ 6 ชั่วโมง',
      _1d: 'วันละครั้ง',
      _3d: 'ทุกๆ 3 วัน',
      _1w: 'สัปดาห์ละครั้ง',
      _1M: 'เดือนละครั้ง',
      _once: 'ครั้งเดียว',
      ADD: 'เพิ่ม',
      START_TIME: 'เวลาเริ่มต้น',
      END_TIME: 'เวลาสิ้นสุด',
      MESSAGES_SETTING: 'การตั้งค่าข้อความ',
      ACTIVATE_CAMPAIGN: 'เปิดใช้งานแคมเปญ',
      START_UP_CONDITION: 'เงื่อนไขการเริ่มใช้งาน',
      ENTER_START_UP_CONDITION: 'เลือกเงื่อนไขการเริ่มใช้งาน',
      CANCEL_upper: 'ยกเลิก',
      SAVE: 'บันทึก',
      ENTER: "เข้าสู่",
      STAY: "พักอยู่",
      BOTH: "ทั้งสอง",
      SEND_MESSAGE: "ส่งข้อความ",
      ADD_CONDITION: "เพิ่มเงื่อนไข",
      ADD_STEP: "เพิ่มขั้นตอน",
      FINISH: "เสร็จสิ้น",
      MESSAGE: "ข้อความ",
      DELETE_SEND_MESSAGE: 'ต้องการลบ การส่งข้อความ?',
      DELETE_SEND_CONDITION: 'ต้องการลบ เงื่อนไข?',
      SELECT: 'เลือก',
      TEXT: '',
      IMAGE: 'รูปภาพ',
      IMAGEMAP: 'แผนที่รูปภาพ',
      CARD: 'การ์ด',
      QUESTIONNAIRE: 'แบบสอบถาม',
      COUPON: 'คูปอง',
      FLEX: 'เฟลค',
      CLOSE: 'ปิด',
      OTHER: 'อื่นๆ',
      _CONDITION: 'เงื่อนไข',
      CONDITION: 'เงื่อนไข',
      CONDITION_NAME: 'ชื่อเงื่อนไข',
      ENTER_CONDITION_NAME: 'ใส่ชื่อเงื่อนไข',
      CONDITIONS: 'เลือกเงื่อนไข',
      NOT_SELECTED_YET: 'ยังไม่มีเงื่อนไขที่ถูกเลือก',
      SPECIFY_CONDITIONS: 'ระบุเงื่อนไข',
      CONFIRM: 'ยืนยัน',
      GENDER: 'เพศ',
      MALE: 'ชาย',
      FEMALE: 'หญิง',
      AGE: 'อายุ',
      _20: 'อายุ 20-24 ปี',
      _25: 'อายุ 25-29 ปี',
      _30: 'อายุ 30-34 ปี',
      _35: 'อายุ 35-39 ปี',
      _40: 'อายุ 40-44 ปี',
      _45: 'อายุ 45-49 ปี',
      _50: 'อายุ 50 ปี ขึ้นไป',
      TRUE: 'เงื่อนไขเป็นจริง',
      CAMPAIGN_INFO: 'ข้อมูลแคมเปญ',
      STATISTICS: 'สถิติ',
      SAVE_MESSAGE: 'บันทึกข้อความ'
    },

    // Broadcast
    BROADCAST: {
      BROADCAST: 'บรอดแคสต์',
      SEARCH_NAME: 'ค้นหาชื่อ',
      TARGET: 'กลุ่มเป้าหมาย',
      BROADCAST_DATE: 'วันที่ใช้งานบรอดแคสต์',
      DO_YOU_CONFIRM_TO_DELETE_THE: 'คุณยืนยันที่จะลบข้อมูลใน',
      DATA_CONFIRM: 'ทั้งหมด',
      NAME: 'ชื่อ',
      RECIPIENT: 'ผู้รับ',
      SPECIFY: 'ระบุกลุ่ม',
      CUSTOMER_GROUPS: 'กลุ่มลูกค้า',
      SELECT_CUSTOMER_GROUPS: 'เลือกกลุ่มลูกค้า',
      UPDATE: 'อัปเดต',
      BASIC_INFO: 'ข้อมูลพื้นฐาน',
      TARGET_GROUP: 'กลุ่มเป้าหมาย',
      PERIOD: 'ระยะเวลา',
      SEND_STATUS: 'สถานะการส่ง',
      SENT: 'ส่งแล้ว',
      PENDING: 'รอดำเนินการ',
    },
  
    // Coupons
    COUPONS: {
      COUPONS: 'คูปอง',
      COUPON: 'คูปอง',
      VALIDITY_PERIOD: 'ระยะเวลาที่ใช้ได้',
      WANNA_DELETE: 'ต้องการลบ',
      _EX: 'ตัวอย่าง',
      COUPON_DESCRIPTION: 'คำอธิบายคูปอง',
      ENTER_COUPON_DESCRIPTION: 'ใส่คำอธิบายคูปอง...',
      THUMBNAIL: 'ภาพตัวอย่างขนาดเล็ก',
      AMOUNT: 'จำนวน',
      QUOTA_PER_USER: 'โควต้าต่อผู้ใช้หนึ่งคน',
      IS_EXTERNAL: 'ใช้ภายนอก',
      FIX_CODE: 'โค้ดที่ถูกระบุไว้',
      ENTER_CODE: 'ใส่โค้ด',
      REMAINING_AMOUNT: 'จำนวนคงเหลือ',
      EXTERNAL_COUPON: 'คูปองจากภายนอก',
      YES: 'ใช่',
      NO: 'ไม่',
      COUPON_IMAGE: 'รูปคูปอง',
      COUPONS_DISTRIBUTED: 'คูปองที่แจกแล้ว',
      CODE: 'โค้ด',
      COUPON_USED_AT: 'ใช้คูปองเมื่อ',
      COUPON_USED_BY: 'คูปองที่ใช้โดย',
      COUPONS_NOT_YET_DISTRIBUTED: 'คูปองที่ยังไม่ได้แจก',
      ACTION: 'การทำงาน',
      IMPORT_COUPON_CODE: 'นำเข้ารหัสคูปอง',
      SEND_COUPON: 'ส่งคูปอง',
      CUSTOMERS: 'ลูกค้า',
      SEARCH_CUSTOMERS: 'ค้นหาลูกค้า',
      SEND: 'ส่ง',
      SENT_SUCCESSFULLY: 'รายการส่งสำเร็จ',
      FAILED_TO_SEND: 'รายการส่งไม่สำเร็จ',
      LIST: 'รายชื่อ',
    },

    // Questionnaire
    QUESTIONNAIRE: {
      QUESTIONNAIRE: 'แบบสอบถาม',
      YOU_CAN_CREATE_A_QUESTIONNAIRE: `คุณสามารถสร้างแบบสอบถามเพื่อเก็บความคิดเห็นจากผู้ใช้ไลน์ได้ โดยเพียงแค่สร้างแบบสอบถามและส่งให้ผู้ใช้ผ่านบรอดแคสต์ หรือผ่านข้อความ เป็นต้น`,
      MEMBER_STATUS: 'สถานะสมาชิก',
      ACTIVATION_STATUS: 'สถานะการเปิดใช้งาน',
      ENTER_MEMBER_STATUS: 'เลือกสถานะสมาชิก',
      ENTER_ACTIVATION_STATUS: 'เลือกสถานะการเปิดใช้งาน',
      MEMBER: 'สมาชิก',
      MEMBERS_ONLY: 'สมาชิกเท่านั้น',
      NOT_LIMITED_TO_MEMBER: 'ไม่จำกัดเฉพาะสมาชิก',
      ENABLE: 'เปิดการใช้งาน',
      DISABLE: 'ปิดการใช้งาน',
      QUESTIONNAIRE_INFO: 'ข้อมูลแบบสอบถาม',
      DETAILS: 'รายละเอียด',
      ENTER_DETAILS: 'ใส่รายละเอียด',
      ENABLED: 'เปิดใช้งาน',
      QUESTIONNAIRE_DESCRIPTION: 'คำอธิบายแบบสอบถาม',
      ENTER_QUESTIONNAIRE_DESCRIPTION: 'ใส่คำอธิบายแบบสอบถาม',
      ADD_QUESTION: 'เพิ่มคำถาม',
      COUPON: 'คูปอง',
      REWARDS: 'รางวัล',
      ADD_COUPON: 'เพิ่มคูปอง',
      NO_COUPON_SELECTED: 'ยังไม่ได้เลือกคูปอง',
      CUSTOMER_GROUPS_CONDITION: 'นำไปใช้ในกลุ่มลูกค้า',
      EXAMPLE: 'ตัวอย่าง',
      TYPE_QUESTION: 'ประเภทคำถาม',
      NEW_QUESTION: 'คำถามใหม่',
      SHORT_ANSWER: 'คำตอบแบบสั้น',
      PARAGRAPH: 'แบบย่อหน้า',
      MULTIPLE_CHOICE: 'ตัวเลือกหลายรายการ',
      CHECK_BOX: 'ช่องทำเครื่องหมาย',
      SHORT_ANSWER_TEXT: 'ข้อความตอบแบบสั้น...',
      LONG_ANSWER_TEXT: 'ข้อความตอบแบบยาว...',
      OPTION: 'ตัวเลือก',
      ADD_OPTION: 'เพิ่มตัวเลือก',
      SELECT_NEW_COUPON: 'เลือกคูปองอันใหม่',
      PLEASE_SELECT_COUPON: 'โปรดเลือกคูปอง',
      NUMBER_OF_COUPONS: 'จำนวนคูปอง',
      MAXIMUM_COUPONS: 'คูปองที่มีอยู่ เต็มแล้ว',
      PLEASE_SELECT_A_COUPON: 'กรุณาเลือกคูปอง',
      AMOUNT_MUST_BE_1_OR_MORE: 'จำนวนคูปองต้องเป็น 1 หรือมากกว่า',
      WARNING: 'คำเตือน',
      YOU_HAVE_NOT_CREATED_ANY_COUPONS: 'คุณยังไม่ได้สร้างคูปอง',
    },

    USER_MENU: {
      ORGANIZE_N_ROLE: 'องค์กร & บทบาท',
      PROFILE: 'โปรไฟล์',
      SETTINGS: 'ตั้งค่า',
      BANNER_SETTINGS: 'ตั้งค่าโฆษณา',
      LOGOUT: 'ออกจากระบบ',
      ADMIN: 'ผู้ดูแล',
      USER: 'ผู้ใช้งาน',
      SYSTEM: 'ซิสเต็ม',
      GENERAL: 'ข้อมูลทั่วไป',
      CHANGE_PASSWORD: 'เปลี่ยนรหัสผ่าน',
      NAME_IS_REQUIRED: 'ต้องระบุชื่อ',
      E_MAIL_IS_REQUIRED: 'ต้องระบุอีเมล',
      E_MAIL: 'อีเมล',
      ENTER_E_MAIL: 'ใส่อีเมล',
      E_MAIL_MUST_BE_A_VALID: 'อีเมล ต้องเป็นอีเมลที่ถูกต้อง',
      SAVE_CHANGES: 'บันทึกการเปลี่ยนแปลง',
      PASSWORD: 'รหัสผ่าน',
      FORGOT_PASSWORD: 'ลืมรหัสผ่าน?',
      NEW_PASSWORD: 'รหัสผ่านใหม่',
      ENTER_NEW_PASSWORD: 'ใส่รหัสผ่านใหม่',
      PASSWORD_IS_REQUIRED: 'ต้องใส่รหัสผ่าน',
      NEW_PASSWORD_IS_REQUIRED: 'ต้องใส่รหัสผ่านใหม่',
      PASSWORD_REQUIRE_AT: 'รหัสผ่านต้องมีอักขระตัวเล็กและตัวใหญ่อย่างน้อย 1 ตัว, ตัวเลข 1 ตัว และต้องมีความยาวมากกว่า 8 ตัว',
      RETYPE_NEW_PASSWORD: 'พิมพ์รหัสผ่านใหม่อีกครั้ง',
      REMEMBER_ME: 'จำรหัสผ่าน',
    },

    OTHER: {
      PAGE_NOT_FOUND: 'ไม่พบเพจ️',
      THE_PAGE_YOU_REQUESTED_IS_NOT_FOUND: 'ไม่พบเพจที่คุณต้องการ',
      BACK_TO_DASHBOARD: 'กลับไปที่แดชบอร์ด',
      YOU_ARE_NOT_AUTHORIZED: 'คุณไม่ได้รับอนุญาต! 🔐',
      YOU_ARE_NOT_PERMISSIONED: 'คุณไม่ได้รับอนุญาต! 🔐',
      YOU_ARE_NOT_PERMISSIONED_HINT: 'บทบาทของคุณไม่สามารถเข้าถึงเพจได้',
      PLEASE_TRY_AGAIN: 'โปรดลองอีกครั้ง',
      BACK_TO_HOME: 'กลับไปหน้าหลัก',
      PLEASE_SIGN_IN_TO_YOUR_ACCOUNT: 'กรุณาลงชื่อเพื่อเข้าใช้บัญชีของคุณ',
      SIGN_IN: 'ลงชื่อ',
    },
  },
};
