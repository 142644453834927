import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinePreviewService {
  selectedMessages:any;
  GetDataStatus:boolean;
  currentUserData:any;

  constructor() { }
}
