<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->

    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>

    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <!--
   <li ngbDropdown class="nav-item dropdown dropdown-language">
     <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
       <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
       ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
     >
     <div ngbDropdownMenu aria-labelledby="dropdown-flag">
       <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
         <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
       </a>
     </div>
   </li>
   -->
   <!--/ Language selection -->

    <!-- Toggle skin -->
    <!--
    <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    -->
    <!--/ Toggle skin -->

    <!-- Search -->
    <!--
    <app-navbar-search></app-navbar-search>
    -->
    <!--/ Search -->

    <!-- Cart -->
    <!--
    <app-navbar-cart></app-navbar-cart>
    -->
    <!--/ Cart -->

    <!-- Notification -->
    <!-- <app-navbar-notification></app-navbar-notification> -->
    <!--/ Notification -->

    <!-- Language Toggle Button -->
    <navbar-language-toggle style="transform: scale(.65);"></navbar-language-toggle>

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="this.currentUser">

          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder mb-50 ml-0 text-capitalize"
              >{{ this.currentUser.name }} {{ this.currentUser.lastName }}</span
            ><span class="user-status text-capitalize">{{ this.currentUser.role }}</span>
          </div>

          <span class="avatar"
            ><img
              class="round"
              src="assets/images/portrait/small/avatar.png"
              alt="avatar"
              height="40"
              width="40" /><span class="avatar-status-online"></span
          ></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">

        <p-treeSelect [(ngModel)]="selectedNode" [options]="treeData" [placeholder]="'USER_MENU.ORGANIZE_N_ROLE' | translate" class="item-dropdown-treeselect" (ngModelChange)="onSelectedNodesChange($event)">
        </p-treeSelect>
        
        <div class="dropdown-divider"></div>

        <a ngbDropdownItem [routerLink]="['/profile']"
          ><span [data-feather]="'user'"   [class]="'mr-50'"></span>{{'USER_MENU.PROFILE' | translate}}</a
        >
        <a *ngIf="currentUser?.role == 'admin' || currentUser?.role == 'system'" ngbDropdownItem [routerLink]="['/banner-setting']"
        ><span [data-feather]="'flag'" [class]="'mr-50'"></span>{{'USER_MENU.BANNER_SETTINGS' | translate}}</a
      >
        <a *ngIf="currentUser?.role == 'admin' || currentUser?.role == 'system'" ngbDropdownItem [routerLink]="['/setting']"
          ><span [data-feather]="'settings'" [class]="'mr-50'"></span>{{'USER_MENU.SETTINGS' | translate}}</a
        >
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span>{{'USER_MENU.LOGOUT' | translate}}</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
