import { NgModule, ErrorHandler } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { FakeDbService } from "@fake-db/fake-db.service";

import "hammerjs";
import { NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";
import { ContextMenuModule } from "@ctrl/ngx-rightclick";

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";

import { coreConfig } from "app/app-config";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { fakeBackendProvider } from "app/auth/helpers"; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from "app/auth/helpers";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";

import { ContextMenuComponent } from "app/main/extensions/context-menu/context-menu.component";
import { AnimatedCustomContextMenuComponent } from "./main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component";
import { BasicCustomContextMenuComponent } from "./main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component";
import { SubMenuCustomContextMenuComponent } from "./main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component";

const appRoutes: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("./backoffice/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "location",
    loadChildren: () =>
      import("./backoffice/location/location.module").then(
        (m) => m.LocationModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "message",
    loadChildren: () =>
      import("./backoffice/message/message.module").then(
        (m) => m.MessageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "beacon",
    loadChildren: () =>
      import("./backoffice/beacon/beacon.module").then((m) => m.BeaconModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "coupon",
    loadChildren: () =>
      import("./backoffice/coupon/coupon.module").then((m) => m.CouponModule),
    canActivate: [AuthGuard],
  },
  {
    path: "auto-response-message",
    loadChildren: () =>
      import(
        "./backoffice/auto-respon-message/auto-respon-message.module"
      ).then((m) => m.AutoResponMessageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "campaign",
    loadChildren: () =>
      import("./backoffice/campaign/campaign.module").then(
        (m) => m.CampaignModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "customer",
    loadChildren: () =>
      import("./backoffice/customer/customer.module").then(
        (m) => m.CustomerModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "groups",
    loadChildren: () =>
      import("./backoffice/customer-group/customer-group.module").then(
        (m) => m.CustomerGroupModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "activities",
    loadChildren: () =>
      import(
        "./backoffice/customer-activities/customer-activities.module"
      ).then((m) => m.CustomerActivitiesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "funnel",
    loadChildren: () =>
      import(
        "./backoffice/funnel/funnel.module"
        ).then((m) => m.FunnelModule),
    canActivate: [AuthGuard],
  },
  {
    path: "heatmap",
    loadChildren: () =>
      import("./backoffice/heatmap/heatmap.module").then(
        (m) => m.HeatmapModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "insight",
    loadChildren: () =>
      import("./backoffice/insight/insight.module").then(
        (m) => m.InsightModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "questionnaire",
    loadChildren: () =>
      import("./backoffice/questionnaire/questionnaire.module").then(
        (m) => m.QuestionnaireModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "broadcast",
    loadChildren: () =>
      import("./backoffice/broadcast/broadcast.module").then(
        (m) => m.BroadcastModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./backoffice/profile/profile.module").then(
        (m) => m.ProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "playground",
    loadChildren: () =>
      import("./backoffice/playground/playground.module").then(
        (m) => m.PlaygroundModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "playground-questionnaire",
    loadChildren: () =>
      import(
        "./backoffice/playground-questionnaire/playground-questionnaire.module"
      ).then((m) => m.PlaygroundQuestionnaireModule),
    canActivate: [AuthGuard],
  },
  {
    path: "setting",
    loadChildren: () =>
      import("./backoffice/setting/setting.module").then(
        (m) => m.SettingModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "wizard-setting",
    loadChildren: () =>
      import("./backoffice/wizard-setting/wizard-setting.module").then(
        (m) => m.WizardSettingModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "banner-setting",
    loadChildren: () =>
      import("./backoffice/banner-setting/banner-setting.module").then(
        (m) => m.BannerSettingModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "logs",
    loadChildren: () =>
      import("./backoffice/device-log/device-log.module").then(
        (m) => m.DeviceLogModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "report",
    loadChildren: () =>
      import("./backoffice/report/report.module").then((m) => m.ReportModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "users",
    loadChildren: () =>
      import("./backoffice/user-management/user-management.module").then(
        (m) => m.UserManagementModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','system']
    },
  },
  {
    path: "apps",
    loadChildren: () =>
      import("./main/apps/apps.module").then((m) => m.AppsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "ui",
    loadChildren: () => import("./main/ui/ui.module").then((m) => m.UIModule),
    canActivate: [AuthGuard],
  },
  {
    path: "components",
    loadChildren: () =>
      import("./main/components/components.module").then(
        (m) => m.ComponentsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "extensions",
    loadChildren: () =>
      import("./main/extensions/extensions.module").then(
        (m) => m.ExtensionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "forms",
    loadChildren: () =>
      import("./main/forms/forms.module").then((m) => m.FormsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "tables",
    loadChildren: () =>
      import("./main/tables/tables.module").then((m) => m.TablesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "charts-and-maps",
    loadChildren: () =>
      import("./main/charts-and-maps/charts-and-maps.module").then(
        (m) => m.ChartsAndMapsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/pages/miscellaneous/error", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [
    AppComponent,
    ContextMenuComponent,
    BasicCustomContextMenuComponent,
    AnimatedCustomContextMenuComponent,
    SubMenuCustomContextMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true,
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
    NgbModule,
    NgbPaginationModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    fakeBackendProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
