export const locale = {
  lang: 'en',
  data: {
    MENU: {
      APPS: {
        SECTION: 'Apps & Pages',
        EMAIL: 'Email',
        CHAT: 'Chat',
        TODO: 'Todo',
        CALENDAR: 'Calendar',
        INVOICE: {
          COLLAPSIBLE: 'Invoice',
          LIST: 'List',
          PREVIEW: 'Preview',
          EDIT: 'Edit',
          ADD: 'Add',
        },
        ECOMMERCE: {
          COLLAPSIBLE: 'eCommerce',
          SHOP: 'Shop',
          DETAIL: 'Details',
          WISHLIST: 'Wish List',
          CHECKOUT: 'Checkout',
        },
        USER: {
          COLLAPSIBLE: 'User',
          LIST: 'List',
          VIEW: 'View',
          EDIT: 'Edit',
        },
      },
      ASSETS: {
        NAME: 'Assets',
        BEACONS: 'Your Beacons',
        LOCATIONS: 'Locations',
        CUSTOMERS: 'Customer Database',
        SECTION: 'Assets',
        HEATMAP: 'Heatmap',
        CUSTOMER_GROUPS: 'Customer Groups',
        ATTENDANCE_REPORT: 'Attendance Report',
        REPORTS: 'Reports',
      },
      CM: {
        SECTION: 'Charts & Maps',
        CHARTS: {
          COLLAPSIBLE: 'Charts',
          BADGE: '2',
          APEX: 'Apex',
          CHARTJS: 'ChartJS',
        },
        MAPS: 'Google Maps',
      },
      DASHBOARD: {
        COLLAPSIBLE: 'Dashboards',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ECOMMERCE: 'eCommerce',
        NAME: 'Dashboard',
        SECTION: 'Info',
        HEATMAP: 'Heatmap',
        ACTIVITIES: 'Customer Activities',
      },
      FT: {
        SECTION: 'Forms & Tables',
        ELEMENT: {
          COLLAPSIBLE: 'Form Elements',
          INPUT: 'Input',
          INPUTGROUPS: 'Input Groups',
          INPUTMASK: 'Input Mask',
          TEXTAREA: 'Textarea',
          CHECKBOX: 'Checkbox',
          RADIO: 'Radio',
          SWITCH: 'Switch',
          SELECT: 'Select',
          NUMBERINPUT: 'Number Input',
          FILEUPLOADER: 'File Uploader',
          QUILLEDITOR: 'Quill Editor',
          FLATPICKER: 'Flatpicker',
          DATETIMEPICKER: 'Date & Time Picker',
        },
        LAYOUTS: 'Form Layouts',
        WIZARD: 'Form Wizard',
        VALIDATION: 'Form Validations',
        REPEATER: 'Form Repeater',
        TABLE: 'Table',
        DATATABLES: 'DataTables',
      },
      MARKETING: {
        NAME: 'Marketing',
        SECTION: 'Marketing',
        CAMPAIGN: 'Campaigns',
        BROADCAST: 'Broadcast',
        INSIGHT: 'Visitor Density',
        QUESTIONNAIRE: 'Questionnaire',
        COUPON: 'Coupon',
        AUTO_RESPONSE_MESSAGE: 'Auto Response Message',
        MESSAGES: 'Messages',
      },
      OTHER: {
        LOGS: 'Device Logs',
        USERS_MANAGEMENT: 'Users Management',
        SETTINGS: 'Settings',
        SECTION: 'Other',
      },
      OTHERS: {
        SECTION: 'Others',
        LEVELS: {
          COLLAPSIBLE: 'Menu Levels',
          SECOND: 'Second Level',
          SECOND1: {
            COLLAPSIBLE: 'Second Level',
            THIRD: 'Third Level',
            THIRD1: 'Third Level',
          },
        },
        DISABLED: 'Disabled Menu',
        DOCUMENTATION: 'Documentation',
        SUPPORT: 'Raise Support',
      },
      PAGES: {
        SECTION: 'Pages',
        AUTH: {
          COLLAPSIBLE: 'Authentication',
          LOGIN1: 'Login V1',
          LOGIN2: 'Login V2',
          REGISTER1: 'Register V1',
          REGISTER2: 'Register V2',
          FORGOTPASSWORD1: 'Forgot Password V1',
          FORGOTPASSWORD2: 'Forgot Password V2',
          RESETPASSWORD1: 'Reset Password V1',
          RESETPASSWORD2: 'Reset Password V2',
        },
        ACCOUNTSETTINGS: 'Account Settings',
        PROFILE: 'Profile',
        FAQ: 'FAQ',
        KB: 'Knowledge Base',
        PRICING: 'Pricing',
        BLOG: {
          COLLAPSIBLE: 'Blog',
          LIST: 'List',
          DETAILS: 'Details',
          EDIT: 'Edit',
        },
        MAIL: {
          COLLAPSIBLE: 'Mail Template',
          WELCOME: 'Welcome',
          RESET: 'Reset Password',
          VERIFY: 'Verify',
          DEACTIVATE: 'Deactivate',
          INVOICE: 'Invoice',
          PROMOTIONAL: 'Promotional',
        },
        MISC: {
          COLLAPSIBLE: 'Miscellaneous',
          COMINGSOON: 'Coming Soon',
          NOTAUTH: 'Not Authorized',
          MAINTENANCE: 'Maintenance',
          ERROR: 'Error',
        },
      },
      REPORTS: {
        FUNNEL: 'Funnel Analytics'
      },
      UI: {
        SECTION: 'User Interface',
        TYPOGRAPHY: 'Typography',
        COLORS: 'Colors',
        FEATHER: 'Feather',
        CARDS: {
          COLLAPSIBLE: 'Cards',
          BADGE: 'New',
          BASIC: 'Basic',
          ADVANCE: 'Advance',
          STATISTICS: 'Statistics',
          ANALYTICS: 'Analytics',
          ACTIONS: 'Actions',
        },
        COMPONENTS: {
          COLLAPSIBLE: 'Components',
          ALERTS: 'Alerts',
          AVATAR: 'Avatar',
          BADGES: 'Badges',
          BREADCRUMBS: 'Breadcrumbs',
          BUTTONS: 'Buttons',
          CAROUSEL: 'Carousel',
          COLLAPSE: 'Collapse',
          DIVIDER: 'Divider',
          DROPDOWNS: 'Dropdowns',
          GROUP: 'List Group',
          OBJECTS: 'Media Objects',
          MODALS: 'Modals',
          COMPONENT: 'Navs',
          PAGINATION: 'Pagination',
          PBADGES: 'Pill Badges',
          PILLS: 'Pills',
          POPOVERS: 'Popovers',
          PROGRESS: 'Progress',
          RATINGS: 'Ratings',
          SPINNER: 'Spinner',
          TABS: 'Tabs',
          TIMELINE: 'Timeline',
          TOASTS: 'Toasts',
          TOOLTIPS: 'Tooltips',
        },
        EX: {
          COLLAPSIBLE: 'Extension',
          SWEET_ALERTS: 'Sweet Alerts',
          BLOCKUI: 'BlockUI',
          TOASTER: 'Toastr',
          SLIDER: 'Slider',
          DRAGDROP: 'Drag & Drop',
          TOUR: 'Tour',
          CLIPBOARD: 'Clipboard',
          MEDIAPLAYER: 'Media Player',
          CONTEXTMENU: 'Context Menu',
          SWIPER: 'Swiper',
          TREEVIEW: 'Tree View',
          I18N: 'I18n',
        },
        LAYOUTS: {
          COLLAPSIBLE: 'Page Layouts',
          COLLAPSED_MENU: 'Collapsed Menu',
          BOXED_LAYOUT: 'Boxed Layout',
          WITHOUT_MENU: 'Without Menu',
          LAYOUT_EMPTY: 'Layout Empty',
          LAYOUT_BLANK: 'Layout Blank',
        },
      },
    },

    // Dashboard
    DASHBOARD:{
      VISITOR_DATA: 'Visitor Data',
      RAW_DATA: 'Raw Data',
      FOR_CROSS_CHECK: 'For cross check (development phase only)',
      DATE: 'Date',
      NEW_VISITOR: 'New Visitor',
      ALL_VISITOR: 'All Visitor',
      UNIQUE_VISITOR: 'Unique Visitor',
      RE_VISITOR: 'Re-Visitor',
      REVISITING_REPORT: 'Revisiting Report',
      REVISIT: 'Revisit',
      IDLE: 'Idle',
      ONE_WEEK: 'week',
      TWO_WEEK: 'weeks',
      AVERAGE: 'Average',
      VIEW_CUSTOMER_ACTIVITIES: 'View Customer Activities',
      TOP_ZONES: 'Top Zones',
      THE_MOST_POPULAR_AREAS: 'The most popular areas where customers pass and average spend time in 24 hours.',
      ZONE: 'Zone',
      DEPARTMENT: 'Department',
      UNIQUE_VISITORS: 'Unique Visitors',
      TIME_SPEND: 'Time Spend',
      UNIQUE: 'Unique',
      LOWEST_ZONES: 'Lowest Zones',
      THE_LESS_POPULAR_AREAS: 'The less popular areas where customers pass and average spend time in 24 hours.',
      CAMPAIGN_REDEMPTION: 'Campaign Redemption',
      THIS_MONTH: 'This Month',
      COUPONS_REDEEMED: 'coupons redeemed',
      VIEW_ALL_CAMPAIGNS: 'View All Campaigns',
      VISITOR_S: 'visitor(s)',
    },

    // Heatmap
    HEATMAP: {
      HEAT_MAP: 'Heatmap',
      DISPLAYS_THE_LOCATION: 'Displays the location of the beacons installed on each floor and the information they collect during the period you want to know.',
      DATE_RANGE: 'Date Range',
      TOTAL_VISITOR: 'Total Visitor',
      UNIQUE_VISITOR: 'Unique Visitor',
      HARDWARE_ID: 'hardware Id',
      NAME: 'name',
      SHOP: 'shop',
      STATUS: 'status',
      FLOOR: 'Floor',
      SELECT_FLOOR: 'Select Floor',
    },

    // Customer Activities
    CUSTOMER_ACTIVITIES: {
      CUSTOMER_ACTIVITIES: 'Customer Activities',
      LOADING: 'Loading...',
      LOADING_SUCCESS: 'Success',
      CHECK_THE_STATUS: 'Check the status of individual customers in the campaign and time period you want to know.',
      SELECT_CAMPAIGN: 'Select Campaign',
      SELECT_CUSTOMER_TYPE: 'Select Customer Type',
      SELECT_CUSTOMER_GROUP: 'Select Customer Group',
      SELECT_CUSTOMER: 'Select Customer',
      DATE_RANGE: 'Date Range',
      SEARCH: 'Search',
      RESULT: 'Result',
      ACTIVITIES_IN_RESULT: 'activities in result',
      EVENT: 'Event',
      CUSTOMER: 'Customer',
      ON_DATE: 'On Date',
      DATA_SAVED: 'Data saved',
    },

    // Visitor Density
    VISITOR_DENSITY: {
      VISITOR_DENSITY: 'Visitor Density',
      YOU_CAN_CHECK: 'You can check the density of customers visiting during the period you want to know.',
      DATE_RANGE: 'Date Range',
      DEVICES_ONLY_ENABLED_DEVICES: 'Devices (only enabled)',
      DEVICES: 'Devices',
      VISITORS: 'Visitors',
      UNIQUE_VISITORS: 'Unique Visitors',
      TOTAL_VISITORS: 'Total Visitors',

    },
    
    // Messages
    MESSAGES: {
      MESSAGE_TEMPLATES: 'Message Templates',
      MESSAGE_TEMPLATES_FOR_QUICK: 'Message templates for quick use in creating campaigns. Tip: Image text that captures the attention of customers is great.',
      TYPE: 'Type',
      SEARCH_TEMPLATE_TYPE: 'Search template type',
      SEARCH: 'Search',
      SEARCH_TEMPLATE_NAME: 'Search template name',
      NAME: 'name',
      MESSAGE: 'message',
      CREATED_AT: 'created at',
      UPDATED_AT: 'updated at',
      TOTAL: 'total',
      TOTAL_ANSWERS: 'Total Answers:',
      EDIT: 'Edit',
      DELETE: 'Delete',
      BACK: 'Back',
      TEXT: 'Text',
      IMAGE: 'Image',
      IMAGE_MAP: 'Image Map',
      IMAGEMAP: 'Image Map',
      COUPON: 'Coupon',
      CAROUSEL: 'Carousel',
      CARD: 'Card',
      QUESTIONNAIRE: 'Questionnaire',
      FLEX: 'Flex',
      ADD_NEW: 'Add New',
      ALL: 'All',
      ENTER_NAME: 'Enter Name',
      TEXT_MESSAGE: 'Text Message',
      ENTER_TEXT_MESSAGE: 'Enter Name',
      SAVE: 'Save',
      IMAGE_MESSAGE: 'Image Message',
      UPLOAD_IMAGE: 'Upload Image',
      FORMAT: 'Format: JPEG, PNG',
      SIZE: 'Size: Up to ',
      ALT_TEXT: 'Alt Text',
      ENTER_ALT_TEXT: 'Enter Alt Text',
      URI: 'URI',
      ENTER_URI: 'Enter URI',
      DIMENSIONS: 'Dimensions: ',
      TITLE: 'Title',
      ENTER_TITLE: 'Enter Title',
      DELETE_CARD: 'Delete Card',
      UPLOAD: 'Upload',
      DESCRIPTION: 'Description',
      ENTER_DESCRIPTION: 'Enter Description',
      IMAGE_BACKGROUND_COLOR: 'Image Background Color',
      ACTIONS: 'Actions',
      ADD_ACTIONS: 'Add Action',
      ENTER_ACTIONS: 'Enter Actions',
      LABEL: 'Label',
      ENTER_LABEL: 'Enter Label',
      MESSAGES: 'Messages',
      ENTER_MESSAGES: 'Enter Messages',
      ENTER_QUESTIONNAIRE: 'Enter Questionnaire',
      FLEX_MESSAGE: 'Flex Message',
      ENTER_FLEX_MESSAGE: 'Enter Flex Message',
    },
    SETTING:{
      LOADING:'Loading',
      SUCCESS:"Success",
      SETTING:'Setting',
      NAME:'Name',
      LOGO:'Logo',
      UPLOAD_IMAGE:'Upload Image',
      REGISTER_DETAIL_CONFIG: 'Register Detail Config',
      LINE_CONFIG:'Line Config',
      INFO_SETTING:'Info Setting',
      LINE_HOOK_URL:'Line Hook URL',
      SAVE:'Save',
      FORCE_CONFIG_OPTION: 'Force Config Option',
      PREFIX:'Prefix (default)',
      FIRSTNAME:'First Name (default)',
      LASTNAME: 'Last Name (default)',
      GENDER: 'Gender (default)',
      AGE: 'Age (default)',
      ID_CARD: 'ID Card',
      MOBILE_PHONE:'Mobile Phone',
      EMAIL:'Email',
      TEXTSPECIFICATION: 'Text Specification',
      PDPA: 'PDPA',
      POLICY: 'Policy',
      LINE_BOT: 'Line Bot',
      CHANNEL_ID:'Channel Id',
      CHANNEL_ACCESS_TOKEN:'Channel Access Token',
      CHANNEL_SECRET:"Channel Secret",
      LINE_LOGIN:'Line Login',
      CHANNEL_ID_REQUIRED:'Channel Id is required',
      CHANNEL_ACCESS_TOKEN_REQUIRED:'Channel Access Token is required',
      CHANNEL_SECRET_REQUIRED:'Channel Secret is required',
      LIFF_ID_REQUIRED:'liff ID is required',
      LIFF_URL_REQUIRED:'liff Url is required',
      SAVE_CHANGE:'Save changes',
      FORMAT:'Format: JPEG, PNG',
      SIZE:'Size: Up to ',
      DIMENSION:'Dimensions: ',
      REF_CODE: 'Ref Code',
      //setting.title | 'แปล'
    },
    DEVICELOG:{
      LOADING:'Loading',
      DEVICELOG:'Device Logs',
      SELECTDEVICE:'Select Device',
      DATARANGE:'Data Range',
      SEARCH:'Search',
    },
    USERMANAGEMENT:{
      USERMANAGEMENT:'User Management',
      ADDNEW:'Add New',
      SEARCH:'Search',
      SEARCHNAME:'Search Name',
      NAME:'name',
      ORGANIZE:'organize',
      ROLE:'role',
      EMAIL:'email',
      CREATEDAT:'created at',
      UPDATEDAT:'updated at',
      TOTAL:'total',
      EDIT: 'Edit',
      CHANGE_PASSWORD:'Change Password',
      DELETE:'Delete',
      BACK:'Back',
      EMAILFORM:'Email',
      PASSWORDFORM:'Password',
      PASSWORDCONFIRM:'Password Confirmation',
      NAMEFORM:'Name',
      ROLEFORM:'Role',
      ORGANIZEFORM:'Organize',
      ACTIONFORM:'Action',
      ADD_USER_ROLE : 'Add User Roles',
      SAVE:'Save',
      ADD:'Add',
      DATAUPDATED:'Data Updated',
      PASSWORDUPDATED:'Passowrd Updated',
      MUST_BE_AT_LEAST_WORD : 'must be at least 8 digits long.',
      SUCCESS:'Success',
    },
    FUNNEL:{
      FUNNELANALYTIC:'Funnel Analytics',
      UNDERSTANDING:'Understanding the steps required to reach an outcome on a flow and how many users get through each of those steps.',
      SELECTCAMPAIGN:'Select Campaign',
      DATERANGE:'Date Range',
      SEARCH:'Search',
      RESULT:'Result',
      LOADING:'Loading',
      TOTAL_CUSTOMER:'Total customer',
      IDLE:"Idle",
      ENTER:'Enter',
      SEND:"Send",
      CLICKED:'Clicked',
      ALL_CUSTOMER:'All Customer',
    },
    ATTENDANCE:{
      ATTENDANCE:'Attendance Report',
      CUSTOMER_GROUP:'Customer Groups',
      DATE:'Date',
      TOTAL:'total',
    },
    CUSTOMERS:{
      CUSTOMER:'Customers',
      ALLCUSTOMER: 'All customers in the system.',
      REGISTER_STATUS:'Register Status',
      SEARCH:'Search',
      SEARCHANOTHER : 'Search name, tel, email, age',
      AGE:'Age',
      LINE_USER_ID:'line user id',
      CREATED_AT:'created At',
      REGISTERED: 'Registerd',
      UNREGISTERED: 'Unregisterd',
      TOTAL:'total',
      LOADING:'Loading',
    },
    CUSTOMERGROUP:{
      CUSTOMER_GROUP:'Customer Groups',
      GROUP_DESCRIPTION:'A group of users created by specifying user attributes and conditions. Once you\'ve defined your audience, you can send messages to a group of users who have the desired qualifications.',
      ADD_NEW:'Add New',
      SEARCH:'Search',
      SEARCH_NAME:'Search name',
      NAME:'Name',
      AMOUNT:'Amount',
      DETAIL:'Detail',
      EDIT:'Edit',
      DELETE:'Delete',
      CREATE_AT:'Create At',
      BACK:'Back',
      GROUP_NAME:'Group Name',
      CUSTOMER_IN_GROUP:'Customer in group',
      MOBILE:'Mobile',
      EMAIL:'Email',
      AUTOUPDATE:'Auto Update',
      CUSTOMERS:'Customers',
      SEARCH_CUSTOMERS:'Search customers',
      STANDARD_QUESTION:'Standard Questions',
      MULTIPLE_QUESTION:'Multiple Questions',
      NEWQUESTION:'New Question:',
      SELECT_QUESTION:"Select Question",
      STATE:"State",
      ADD_NEW_QUESTION:"Add New Question",
      TARGET_ESTIMATE:"Target Estimate",
      AMOUNT_CUSTOMER_IN_GROUP:"Amount Customer in group",
      FROM:"from",
      CALCULATE_AS:"calculated as",
      TOTAL:"total",
      UNIT:"",
      SAVE:"SAVE",
      // MALE:'Male',
      // FEMALE:'Female',
      // YEARS1:'20 - 24 years old',
      // YEARS2:'25 - 29 years old',
      // YEARS3:'30 - 34 years old',
      // YEARS4:'35 - 39 years old',
      // YEARS5:'40 - 44 years old',
      // YEARS6:'45 - 49 years old',
      // YEARS7:'50 years or older'

    },
    LOCATION:{
      LOCATION:'Locations',
      THE_WHOLE_FLOOR:'The whole floor plan of the building for analyzing customer journey data.',
      SEARCH_FLOOR:'Search floor',
      FLOOR:'Floor',
      CREATED_AT:'Created At',
      TOTAL:'total',
      DELETE:'Delete',
      LOADING:'Loading',
      CONFIRM_DELETE:'Confirm deletion',
      CONFIRM_DELETE_FLOOR:'Confirm deletion floor',
      CONFIRM_DELETE_DEPARTMENT:'Confirm deletion department',
      CONFIRM_DELETE_SHOP:'Confirm deletion shop',
      BACK:'Back',
      EDIT:'Edit',
      CANCEL:'Cancel',
      CLOSE:'Close',
      DEPARTMENT:'department',
      DO_YOU_CONFIRM1:'Do you confirm to delete the',
      DO_YOU_CONFIRM2:'data ?',
      ADD_NEWDEPARTMENT_FORM:'Add New Department',
      DEPARTMENT_FORM:'Department',

    },

    // Campaigns
    CAMPAIGNS: {
      CAMPAIGNS: 'Campaigns',
      YOU_CAN_AUTOMATICALLY_SEND: 'You can automatically send messages to users who are friends with official accounts according to the conditions you want.',
      ADD_NEW: 'Add New',
      SEARCH: 'Search',
      SEARCH_CAMPAIGN_NAME: 'Search campaign name',
      NAME: 'NAME',
      PERIOD: 'PERIOD',
      PRIORITY: 'PRIORITY',
      CREATED_AT: 'created at',
      UPDATED_AT: 'updated at',
      TOTAL: 'total',
      DETAIL: 'Detail',
      EDIT: 'Edit',
      DELETE: 'Delete',
      CONFIRM_DELETION: 'Confirm Deletion',
      CANCEL: 'Cancel',
      WARNING: 'WARNING !!',
      YOU_DONT_HAVE_LOCATIONS_AND_CUSTOMER_GROUPS: `You don't have Locations and Customer Groups.`,
      OK: 'OK',
      BASIC_INFORMATION: 'Basic Information',
      ENTER_NAME: 'Enter Name',
      CAMPAIGN_PERIOD: 'Campaign Period',
      START_DATE: 'Start Date',
      END_DATE: 'End Date',
      LOCATION: 'Location',
      SELECT_SHOP: 'Select Shop',
      SELECT_A_LEVEL: 'Select a Level',
      ALWAYS: 'Always',
      SPECIFIC_TIME: 'Specific Time',
      RECEIVING_FREQUENCY: 'Receiving Frequency',
      ENTER_RECEIVING_FREQUENCY: 'Enter Receiving Frequency',
      BROADCAST: 'Broadcast',
      CUSTOMER_GROUP: 'Customer Group',
      ENTER_CUSTOMER_GROUP: 'Enter Customer Group',
      PRIORITY_lower: 'Priority',
      PRIORITY_info: 'Priority',
      HIGH: 'high',
      MEDIUM: 'medium',
      NORMAL: 'normal',
      SUNDAY: 'Sunday',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      _10m: 'Every 10 minutes',
      _30m: 'Every 30 minutes',
      _1h: 'Every a hour',
      _6h: 'Every 6 hours',
      _1d: 'Once a day',
      _3d: 'Every 3 days',
      _1w: 'Once a week',
      _1M: 'Once a month',
      _once: 'Once a campaign',
      ADD: 'Add',
      START_TIME: 'Start Time',
      END_TIME: 'End Time',
      MESSAGES_SETTING: 'Messages Setting',
      ACTIVATE_CAMPAIGN: 'Activate Campaign',
      START_UP_CONDITION: 'Start-Up Condition',
      ENTER_START_UP_CONDITION: 'Enter Start-Up Condition',
      CANCEL_upper: 'CANCEL',
      SAVE: 'SAVE',
      ENTER: "enter",
      STAY: "stay",
      BOTH: "both",
      SEND_MESSAGE: "Send Message",
      ADD_CONDITION: "Add Condition",
      ADD_STEP: "Add Step",
      FINISH: "Finish",
      MESSAGE: 'Message',
      DELETE_SEND_MESSAGE: 'Delete Send Message?',
      DELETE_SEND_CONDITION: 'Delete Condition?',
      SELECT: 'Select',
      TEXT: 'Text',
      IMAGE: 'Image',
      IMAGEMAP: 'Image Map',
      CARD: 'Card',
      QUESTIONNAIRE: 'Questionnaire',
      COUPON: 'Coupon',
      FLEX: 'Flex',
      CLOSE: 'Close',
      OTHER: 'Other',
      _CONDITION: 'Condition',
      CONDITION: 'Condition',
      CONDITION_NAME: 'Condition Name',
      ENTER_CONDITION_NAME: 'Enter Condition Name',
      CONDITIONS: 'Conditions',
      NOT_SELECTED_YET: 'Not selected yet',
      SPECIFY_CONDITIONS: 'Specify conditions',
      CONFIRM: 'Confirm',
      GENDER: 'Gender',
      MALE: 'Male',
      FEMALE: 'Female',
      AGE: 'Age',
      _20: '20-24 Years old',
      _25: '25-29 Years old',
      _30: '30-34 Years old',
      _35: '35-39 Years old',
      _40: '40-44 Years old',
      _45: '45-49 Years old',
      _50: '50 years or older',
      TRUE: 'true',
      CAMPAIGN_INFO: 'Campaign Info',
      STATISTICS: 'Statistics',
      SAVE_MESSAGE: 'Save Message'
    },

    // Broadcast
    BROADCAST: {
      BROADCAST: 'Broadcast',
      SEARCH_NAME: 'Search name',
      TARGET: 'target',
      BROADCAST_DATE: 'broadcast date',
      DO_YOU_CONFIRM_TO_DELETE_THE: 'Do you confirm to delete the',
      DATA_CONFIRM: 'data',
      NAME: 'Name',
      RECIPIENT: 'Recipient',
      SPECIFY: 'Specify',
      CUSTOMER_GROUPS: 'Customer Groups',
      SELECT_CUSTOMER_GROUPS: 'Select Customer Groups',
      UPDATE: 'Update',
      BASIC_INFO: 'Basic Info',
      TARGET_GROUP: 'Target Group',
      PERIOD: 'Period',
      SEND_STATUS: 'Send Status',
      SENT: 'Sent',
      PENDING: 'Pending',
    },

    // Coupons
    COUPONS: {
      COUPONS: 'Coupons',
      COUPON: 'Coupon',
      VALIDITY_PERIOD: 'Validity period',
      WANNA_DELETE: 'Delete',
      _EX: 'Ex',
      COUPON_DESCRIPTION: 'Coupon description',
      ENTER_COUPON_DESCRIPTION: 'Enter Coupon description...',
      THUMBNAIL: 'Image',
      AMOUNT: 'Amount',
      QUOTA_PER_USER: 'Quota Per User',
      IS_EXTERNAL: 'is External',
      FIX_CODE: 'Fix Code',
      ENTER_CODE: 'Enter Code',
      REMAINING_AMOUNT: 'Remaining Amount',
      EXTERNAL_COUPON: 'External Coupon',
      YES: 'Yes',
      NO: 'No',
      COUPON_IMAGE: 'Coupon Image',
      COUPONS_DISTRIBUTED: 'Coupons Distributed',
      CODE: 'Code',
      COUPON_USED_AT: 'Coupon Used at',
      COUPON_USED_BY: 'Coupon Used By',
      COUPONS_NOT_YET_DISTRIBUTED: 'Coupons Undistributed',
      ACTION: 'Action',
      IMPORT_COUPON_CODE: 'Import Coupon Code',
      SEND_COUPON: 'Send Coupon',
      CUSTOMERS: 'Customers',
      SEARCH_CUSTOMERS: 'Search Customers',
      SEND: 'Send',
      SENT_SUCCESSFULLY: 'Sent Successfully',
      FAILED_TO_SEND: 'Failed to Send',
      LIST: 'List',
    },

    // Questionnaire
    QUESTIONNAIRE: {
      QUESTIONNAIRE: 'Questionnaire',
      YOU_CAN_CREATE_A_QUESTIONNAIRE: `You can create a questionnaire to collect LINE users' opinions. Just create a questionnaire and send it to users via broadcast, message, etc.`,
      MEMBER_STATUS: 'Member Status',
      ACTIVATION_STATUS: 'Activation Status',
      ENTER_MEMBER_STATUS: 'Select Member Status',
      ENTER_ACTIVATION_STATUS: 'Select Activation Status',
      MEMBER: 'Member',
      MEMBERS_ONLY: 'Members Only',
      NOT_LIMITED_TO_MEMBER: 'Not Limited To Member',
      ENABLE: 'Enable',
      DISABLE: 'Disable',
      QUESTIONNAIRE_INFO: 'Questionnaire Info',
      DETAILS: 'Details',
      ENTER_DETAILS: 'Enter Details',
      ENABLED: 'Enabled',
      QUESTIONNAIRE_DESCRIPTION: 'Questionnaire Description',
      ENTER_QUESTIONNAIRE_DESCRIPTION: 'Enter Questionnaire Description',
      ADD_QUESTION: 'Add Question',
      COUPON: 'Coupon',
      REWARDS: 'Rewards',
      ADD_COUPON: 'Add Coupon',
      NO_COUPON_SELECTED: 'No Coupon Selected',
      CUSTOMER_GROUPS_CONDITION: 'Customer Groups Condition',
      EXAMPLE: 'Example',
      TYPE_QUESTION: 'Type Question',
      NEW_QUESTION: 'New Question',
      SHORT_ANSWER: 'Short Answer',
      PARAGRAPH: 'Paragraph',
      MULTIPLE_CHOICE: 'Multiple Choice',
      CHECK_BOX: 'Check Box',
      SHORT_ANSWER_TEXT: 'Short answer text ...',
      LONG_ANSWER_TEXT: 'Long answer text ...',
      OPTION: 'Option',
      ADD_OPTION: 'Add option',
      SELECT_NEW_COUPON: 'Select New Coupon',
      PLEASE_SELECT_COUPON: 'Please Select Coupon',
      NUMBER_OF_COUPONS: 'Number of Coupons',
      MAXIMUM_COUPONS: 'Maximum Coupons',
      PLEASE_SELECT_A_COUPON: 'Please select a coupon',
      AMOUNT_MUST_BE_1_OR_MORE: 'Amount must be 1 or more',
      WARNING: 'Warning',
      YOU_HAVE_NOT_CREATED_ANY_COUPONS: 'You have not created any coupons',
    },

    USER_MENU: {
      ORGANIZE_N_ROLE: 'Organize & Role',
      PROFILE: 'Profile',
      SETTINGS: 'Settings',
      BANNER_SETTINGS: 'Banner Settings',
      LOGOUT: 'Logout',
      ADMIN: 'Admin',
      USER: 'User',
      SYSTEM: 'System',
      GENERAL: 'General',
      CHANGE_PASSWORD: 'Change Password',
      NAME_IS_REQUIRED: 'Name is required',
      E_MAIL_IS_REQUIRED: 'Email is required',
      E_MAIL: 'Email',
      ENTER_E_MAIL: 'Enter Email',
      E_MAIL_MUST_BE_A_VALID: 'Email must be a valid email address',
      SAVE_CHANGES: 'Save changes',
      PASSWORD: 'Password',
      FORGOT_PASSWORD: 'Forgot Password?',
      NEW_PASSWORD: 'New Password',
      ENTER_NEW_PASSWORD: 'Enter New Password',
      PASSWORD_IS_REQUIRED: 'Password is required',
      NEW_PASSWORD_IS_REQUIRED: 'New Password is required',
      PASSWORD_REQUIRE_AT: 'Password require at least 1 lower and upper charactor, 1 number and length greater than 8',
      RETYPE_NEW_PASSWORD: 'Retype New Password',
      REMEMBER_ME: 'Remember Me',
    },

    OTHER: {
      PAGE_NOT_FOUND: 'Page Not Found',
      THE_PAGE_YOU_REQUESTED_IS_NOT_FOUND: 'The page you requested is not found.',
      BACK_TO_DASHBOARD: 'Back to dashboard',
      YOU_ARE_NOT_AUTHORIZED: 'You are not authorized! 🔐',
      YOU_ARE_NOT_PERMISSIONED: 'You are not permissioned! 🔐',
      YOU_ARE_NOT_PERMISSIONED_HINT: 'Your role cannot to access the page.',
      PLEASE_TRY_AGAIN: 'Please try again',
      BACK_TO_HOME: 'Back to home',
      PLEASE_SIGN_IN_TO_YOUR_ACCOUNT: 'Please sign-in to your account',
      SIGN_IN: 'Sign in',
    },
  },
};
