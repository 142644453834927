import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from 'app/services/logger.service';
import { SetLanguageService } from 'app/services/set-language.service';
import { NavbarComponent } from '../navbar.component';

const log = new Logger('NavbarLanguageToggleComponent');

@Component({
  selector: 'navbar-language-toggle',
  templateUrl: './navbar-language-toggle.component.html',
  styleUrls: ['./navbar-language-toggle.component.scss']
})
export class NavbarLanguageToggleComponent implements OnInit {
  // default language
  selectedLang:string;

  isLoading:boolean = false;

  constructor(
    private _setLanguage: SetLanguageService,
    private _router: Router,
    private navbar: NavbarComponent) { }

  ngOnInit(): void {
    this.selectedLang = localStorage.getItem('selectedLanguage') || ''
    localStorage.setItem('selectedLanguage', this.selectedLang);

    this._setLanguage.selectedLanguage = localStorage.getItem('selectedLanguage');
    this._setLanguage.updateSelectedLanguage(this._setLanguage.selectedLanguage);

    // log.info('Default language:', this._setLanguage.selectedLanguage);
  }

  switchLanguage(language: string): void {
    this.isLoading = true;

    if(language !== 'en') {
      this.selectedLang = 'en'
      localStorage.setItem('selectedLanguage', this.selectedLang);

      this._setLanguage.selectedLanguage = localStorage.getItem('selectedLanguage');
      this._setLanguage.updateSelectedLanguage(this._setLanguage.selectedLanguage);
      // log.info('Language:', this._setLanguage.selectedLanguage);
    } 
    
    if(language !== 'th'){
      this.selectedLang = 'th'
      localStorage.setItem('selectedLanguage', this.selectedLang);

      this._setLanguage.selectedLanguage = localStorage.getItem('selectedLanguage');
      this._setLanguage.updateSelectedLanguage(this._setLanguage.selectedLanguage);
      // log.info('Language:', this._setLanguage.selectedLanguage);
    }

    const currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.navbar.refreshComponent();
      this._router.navigateByUrl(currentUrl);
    });
        
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }
}
