import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(
          map(result => {
            // login successful if there's a jwt token in the response
            //console.log("login success = %o", result.user)
            if (result.user && result.tokens) {
              result.user.token = result.tokens.access.token
              result.user.refreshToken = result.tokens.refresh.token
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(result.user));

              // notify
              this.currentUserSubject.next(result.user);
            }

            return result.user;
          })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }

  isTokenExpired(){
    const _user = JSON.parse(localStorage.getItem('currentUser'));
    const current = new Date()
    const expiredOn = Date.parse(_user.tokens.access.expires)
    return (current.getTime() > expiredOn)
  }

  updateOrganizeCurrentUser(updatedOrganize,role = undefined){
    let _user = JSON.parse(localStorage.getItem('currentUser'));
    _user.organize = updatedOrganize

    if(role){
      _user.role = role.toLowerCase( )
    }
    
    localStorage.setItem('currentUser', JSON.stringify(_user));
    this.currentUserSubject.next(_user);
  }
}
