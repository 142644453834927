import { IQueryParams } from "app/interfaces/query-params.interface";
import { SortDirectionType } from "app/types/query-params.type";

export const queryParamsToUrl = (params: IQueryParams) => {
  if (!params) return "";
  const keys = Object.keys(params);
  const stringParam = keys
    .map((key, i) => {
      const symbol = i === 0 ? "?" : "&";
      return `${symbol}${key}=${params[key]}`;
    })
    .join("");
  return stringParam;
};

export const sortByToString = (sortBy: { [key: string]: string }): string => {
  const sortString = JSON.stringify(sortBy);
  const unquoted = sortString.replace(/"/g, "");
  return unquoted.substring(1, unquoted.length - 1);
};

export const toggleSortDirection = (direction: SortDirectionType) => {
  if (!direction) return SortDirectionType.desc;
  return direction === SortDirectionType.desc
    ? SortDirectionType.asc
    : SortDirectionType.desc;
};
