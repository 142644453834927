import { MessageModule } from './../../../app/backoffice/message/message.module';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CoreDirectivesModule } from "@core/directives/directives";
import {
  NgbPaginationModule,
  NgbNavModule,
  NgbTooltipModule,
  NgbPopoverModule,
} from "@ng-bootstrap/ng-bootstrap";
import { LinxPaginationModule } from "@core/components/linx-pagination/linx-pagination.module";
import { LinxInputModule } from "@core/components/linx-input/linx-input.module";
import { LinxDisplayModule } from "@core/components/linx-display/linx-display.module";

import { LinxMessageTemplateComponent } from "./message-template/linx-message-template.component";
import { MessagePreviewFormComponent } from './message-preview-form/message-preview-form.component';
import {TabViewModule} from 'primeng/tabview';
import {CarouselModule} from 'primeng/carousel';
import {SkeletonModule} from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFlexMessagesModule } from 'ngx-flex-messages';

@NgModule({
  declarations: [
    LinxMessageTemplateComponent,
    MessagePreviewFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbPopoverModule,
    CoreDirectivesModule,
    LinxInputModule,
    LinxDisplayModule,
    LinxPaginationModule,
    TabViewModule,
    CarouselModule,
    SkeletonModule,
    TranslateModule,
    NgxFlexMessagesModule
  ],
  exports: [LinxMessageTemplateComponent],
})
export class LinxMessageTemplateModule {}
