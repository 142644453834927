import { BackendService } from 'app/services/backend.service';
import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from 'app/auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { User } from 'app/auth/models';

import { coreConfig } from 'app/app-config';
import { Router } from '@angular/router';
import { NodeService } from 'app/backoffice/campaign/service/nodeservice.service';
import { Logger } from 'app/services/logger.service';
import { NavbarService } from 'app/services/navbar.service';

const log = new Logger("VerticalMenuComponent");
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;
  
  public myUserRoles:any = []

  selectedNode: any ;
  public treeData = [
    {
      "id": 'OrganizeID01',
      "label": "Organize A",
      // "expanded": true, // todo: add logic active organize&role
      "selectable": false,
      "children": [
        {
          "id": 'RoleID01',
          "label": "admin",
        },
        {
          "id": 'RoleID02',
          "label": "user",
        },
      ]
    },
    {
      "id": 'OrganizeID02',
      "label": "Organize B",
      // "expanded": true, // todo: add logic active organize&role
      "selectable": false,
      "children": [
        {
          "id": 'RoleID03',
          "label": "admin",
        },
        {
          "id": 'RoleID04',
          "label": "user",
        },
      ]
    },
  ]

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    public nodeService: NodeService,
    private _backendService: BackendService,
    private _navbar: NavbarService
  ) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      fr: {
        title: 'French',
        flag: 'fr'
      },
      de: {
        title: 'German',
        flag: 'de'
      },
      pt: {
        title: 'Portuguese',
        flag: 'pt'
      },
      th: {
        title: 'Thai',
        flag: 'th'
      }
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
    this._router.navigate(['/pages/authentication/login-v2']);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(){
    // get the currentUser details from localStorage
    this._navbar.currentUser$.subscribe(currentUser => {
      this.currentUser = currentUser;
    });

    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });

    if(this.currentUser){
      await this.fetchMyUserRole()
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async fetchMyUserRole(){
    this.myUserRoles = await this._backendService.getMyUserRoles()
    log.debug("fetchMyUserRole this.myUserRoles => %o",this.myUserRoles)

    this.treeData = await this.prepareTreeData(this.myUserRoles)
    
  }

  prepareTreeData(arrays){

    let parentElements =  arrays.map((myUserRole:any) => {
      const organizeId = myUserRole.organize.id
      const organizeName = myUserRole.organize.name
      const parentElement =  {
        "id": organizeId,
        "label": organizeName,
        "expanded": this.currentUser?.organize?.id == organizeId ? true : false, 
        "selectable": false,
        "children": []
      }
      return parentElement
    })
    parentElements = _.uniqBy(parentElements, 'id')
    // log.debug("fetchMyUserRole parentElements => %o",parentElements)
    
    let childrenElements = arrays.map((myUserRole) => {
      const organizeId = myUserRole.organize.id
      // const role = this._translateService.instant('USER_MENU.' + _.upperCase(myUserRole.role))
      const role = myUserRole.role
      const userRoleId = myUserRole.id
      const childrenElement =  {
        "parentId": organizeId,
        "styleClass": this.currentUser?.organize?.id == organizeId && this.currentUser.role == role ? 'selected-element' : '', 
        "label": this._translateService.instant('USER_MENU.' + _.upperCase(myUserRole.role)),
        "label_value": role,
        "userRoleId": userRoleId,
        "icon": 'pi pi-angle-right'
      }
      return childrenElement
    })
    // log.debug("fetchMyUserRole childrenElements => %o",childrenElements)

    const treeData = parentElements.map((parentElement) => {
      parentElement["children"] = childrenElements.filter(it => it.parentId == parentElement.id)
      return parentElement
    })
    // log.debug("fetchMyUserRole treeData => %o",treeData)
    
    return treeData
  }

  async onSelectedNodesChange(node){
    // log.debug("onSelectedNodesChange node => %o",node)
    // log.debug("onSelectedNodesChange this.selectedNode => %o",this.selectedNode)

    const role = node
    const organize = node?.parent
    const selectedRole = role?.label_value
    const selectedUserRoleId = role?.userRoleId
    const selectedOrganize = { id: organize?.id , lable: organize?.label_value }


    log.debug("onSelectedNodesChange selectedUserRoleId => %o",selectedUserRoleId)
    log.debug("onSelectedNodesChange selectedRole => %o",selectedRole)
    // log.debug("onSelectedNodesChange selectedOrganize => %o",selectedOrganize)

    const data:any = await this._backendService.switchUserRoleAndOrganize(selectedUserRoleId)
    log.debug("onSelectedNodesChange data => %o",data)

    if(data){
      // log.debug("onSelectedNodesChange this.myUserRoles => %o",this.myUserRoles)
      const myUserRoleInstance = this.myUserRoles.find( myUserRole => myUserRole.organize.id == selectedOrganize.id )
      // log.debug("onSelectedNodesChange myUserRoleInstance => %o",myUserRoleInstance)
      
      await this._authenticationService.updateOrganizeCurrentUser(myUserRoleInstance.organize,selectedRole)
      location.reload();
    }
  }

  async refreshComponent() {
    await this.fetchMyUserRole()
  }
}
