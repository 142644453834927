import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Logger } from "./logger.service";
import {
  IDensitiesParam,
  IQueryParams,
} from "../interfaces/query-params.interface";
import * as ICampaign from "app/interfaces/campaign.interface";
import * as ICustomer from "app/interfaces/customers.interface";
import * as IUser from "app/interfaces/user.interface";
import * as ICustomerGroup from "app/interfaces/customer-group.interface";
import * as IDevice from "app/interfaces/devices.interface";
import * as IFloor from "app/interfaces/location-floors.interface";
import * as IDepartment from "app/interfaces/location-departments.interface";
import * as IShop from "app/interfaces/location-shops.interface";
import * as IDashboard from "app/interfaces/dashboard.interface";
import * as IQuestionnaire from "app/interfaces/questionnaire.interface";
import * as IBroadcast from "app/interfaces/broadcast.interface";
import * as IReport from "app/interfaces/report.interface";
import {
  GetResponse,
  Message,
  PostPayload,
} from "app/interfaces/message-templetes.interface";
import { ListResponse } from "app/interfaces/customer-group.interface";
import { queryParamsToUrl } from "utils/query-param";
import {
  UploadFilePayload,
  UploadFileResponse,
} from "app/interfaces/upload-file.interface";
import { Subject } from "rxjs";

const log = new Logger("backend-service");

@Injectable({
  providedIn: "root",
})
export class BackendService {
  API_URL = environment.apiUrl;
  public _updateOrganizeSetting = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  // organize
  getOrganizes(queryParams: IQueryParams): Promise<any> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/organizes${_queryParams}`;
        const response = await this.http.get<any>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getAttendanceLogs$(queryParams: IQueryParams) {
    const _queryParams = queryParamsToUrl(queryParams);
    const url = `${this.API_URL}/attendance${_queryParams}`;
    return this.http.get<IReport.AttendanceList>(url);
  }

  // users

  getUsers(queryParams: IQueryParams): Promise<IUser.ListResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/users${_queryParams}`;
        const response = await this.http
          .get<IUser.ListResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getUserById(id: string): Promise<IUser.UserResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/users/${id}`;
        const response = await this.http.get<IUser.UserResult>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  createUser(body: IUser.UserFormValue): Promise<IUser.UserResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/users`;
        const response = await this.http
          .post<IUser.UserResult>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteUserById(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/users/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateUserById(
    id: string,
    body: IUser.UserFormValue
  ): Promise<IUser.UserResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/users/${id}`;
        const response = await this.http
          .patch<IUser.UserResult>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateUserRoleById(
    id: string,
    payload
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/userRole/${id}`;
        const response = await this.http
          .patch(url, payload)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  changePasswordByAdmin(
    payload
  ){
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/users/changePasswordByAdmin`;
        const response = await this.http
          .post(url, payload)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  forgotPassword(
    payload
  ){
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/auth/forgot-password`;
        const response = await this.http
          .post(url, payload)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  resetPassword(
    token,
    payload
  ){
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/auth/reset-password?token=${token}`;
        const response = await this.http
          .post(url, payload)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }


  createUserRoleInOrganize(
    payload
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/userRole/createInOrganize`;
        const response = await this.http
          .post(url, payload)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getUserRolesByUserId(userId) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/userRole/getByUser/${userId}`;
        const response = await this.http
          .get(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getMyUserRoles() {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/userRole/me`;
        const response = await this.http
          .get(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteUserRole(userRoleId) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/userRole/${userRoleId}`;
        const response = await this.http
          .delete(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  switchUserRoleAndOrganize(userRoleId) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/userRole/switch/${userRoleId}`;
        const response = await this.http
          .post(url, {})
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // customers

  getCustomers(queryParams: IQueryParams): Promise<ICustomer.GetResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/customers/${_queryParams}`;
        const response = await this.http
          .get<ICustomer.GetResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCustomerById(id: string): Promise<ICustomer.Customer> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/customers/${id}`;
        const response = await this.http
          .get<ICustomer.Customer>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateCustomerById(id: string,body): Promise<ICustomer.Customer> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/customers/${id}`;
        const response = await this.http
          .patch<ICustomer.Customer>(url,body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  searchCustomers(keyword: string): Promise<ICustomer.GetResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/customers/search?keyword=${keyword}`;
        const response = await this.http
          .get<ICustomer.GetResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  searchCustomers$(keyword: string) {
    const url = `${this.API_URL}/admin/customers/search?keyword=${keyword}`;
    return this.http.get<ICustomer.GetResponse>(url);
  }

  importDataCustomersRefCode(payload){
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/customers/import/refCode`;
        const response = await this.http
          .post(url,payload)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  importDataCustomersLineId(payload){
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/customers/import/lineId`;
        const response = await this.http
          .post(url,payload)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }
  

  // customer group

  getCustomerGroups(
    queryParams: IQueryParams
  ): Promise<ICustomerGroup.ListResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/groups/${_queryParams}`;
        const response = await this.http
          .get<ICustomerGroup.ListResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCustomerGroupById(id: string): Promise<ICustomerGroup.CustomerGroup> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/groups/${id}`;
        const response = await this.http
          .get<ICustomerGroup.CustomerGroup>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCustomerGroupById$(id: string) {
    const url = `${this.API_URL}/admin/groups/${id}?limit=5`;
    return this.http.get<ICustomerGroup.CustomerGroup>(url);
  }

  createCustomerGroup(
    body: ICustomerGroup.CustomerGroupForm
  ): Promise<ICustomerGroup.PostResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/groups`;
        const response = await this.http
          .post<ICustomerGroup.PostResponse>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateCustomerGroupById(
    id: string,
    body: ICustomerGroup.CustomerGroupForm
  ): Promise<ICustomerGroup.PostResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/groups/${id}`;
        const response = await this.http
          .patch<ICustomerGroup.PostResponse>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteCustomerGroupById(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/groups/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  
  // banners 
  getBanners(queryParams){
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/banner/${_queryParams}`;
        const response = await this.http
          .get(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getBannerById(id: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/banner/${id}`;
        const response = await this.http.get<any>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  createBanner(body: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/banner`;
        const response = await this.http
          .post(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  editBannerById(id: string,body: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/banner/${id}`;
        const response = await this.http
          .patch<any>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteBannerById(id: string): Promise<null>{
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/banner/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // devices
  getDevices(queryParams: IQueryParams): Promise<IDevice.GetResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/devices/${_queryParams}`;
        const response = await this.http
          .get<IDevice.GetResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getDevicesByFloor(
    queryParams: IQueryParams,
    floorId: string
  ): Promise<IDevice.DeviceByFloor[]> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/floors/devices/${floorId}${_queryParams}`;
        const response = await this.http
          .get<IDevice.DeviceByFloor[]>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getFirstDeviceByShop(
    queryParams: IQueryParams,
    shopId: string
  ): Promise<IDevice.DeviceByFloor[]> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/devices/shop/${shopId}${_queryParams}`;
        const response = await this.http
          .get<IDevice.DeviceByFloor[]>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getDeviceById(id: string): Promise<IDevice.Device> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/devices/${id}`;
        const response = await this.http.get<IDevice.Device>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  createDevice(body: IDevice.DeviceForm): Promise<IDevice.Device> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/devices`;
        const response = await this.http
          .post<IDevice.Device>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  editDeviceById(
    id: string,
    body: IDevice.DeviceForm
  ): Promise<IDevice.Device> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/devices/${id}`;
        const response = await this.http
          .patch<IDevice.Device>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteDeviceById(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/devices/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // location-floor
  getLocationFloor(queryParams: IQueryParams): Promise<IFloor.GetResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/floors/${_queryParams}`;
        const response = await this.http
          .get<IFloor.GetResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  createLocationFloor(
    body: IFloor.FloorForm
  ): Promise<IFloor.PostResponseFloor> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/floors`;
        const response = await this.http
          .post<IFloor.PostResponseFloor>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getLocationFloorById(id: string): Promise<IFloor.Floor> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/floors/${id}`;
        const response = await this.http.get<IFloor.Floor>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  editLocationFloorById(
    id: string,
    body: IFloor.FloorForm
  ): Promise<IFloor.PostResponseFloor> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/floors/${id}`;
        const response = await this.http
          .patch<IFloor.PostResponseFloor>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteLocationFloorById(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/floors/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // location-department
  getLocationDepartment(
    queryParams: IQueryParams
  ): Promise<IDepartment.GetResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/departments${_queryParams}`;
        const response = await this.http
          .get<IDepartment.GetResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  createLocationDepartment(
    body: IDepartment.DepartmentForm
  ): Promise<IDepartment.PostResponseDepartment> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/departments`;
        const response = await this.http
          .post<IDepartment.PostResponseDepartment>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getLocationDepartmentById(id: string): Promise<IDepartment.Department> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/departments/${id}`;
        const response = await this.http
          .get<IDepartment.Department>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  editLocationDepartmentById(
    id: string,
    body: IDepartment.DepartmentForm
  ): Promise<IDepartment.PostResponseDepartment> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/departments/${id}`;
        const response = await this.http
          .patch<IDepartment.PostResponseDepartment>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteLocationDepartmentById(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/departments/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // location-shop
  getLocationShop(queryParams?: IQueryParams): Promise<IShop.GetResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/shops/${_queryParams}`;
        const response = await this.http
          .get<IShop.GetResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  createLocationShop(body: IShop.ShopForm): Promise<IShop.PostResponseShop> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/shops`;
        const response = await this.http
          .post<IShop.PostResponseShop>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getLocationShopById(id: string): Promise<IShop.Shop> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/shops/${id}`;
        const response = await this.http.get<IShop.Shop>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  editLocationShopById(
    id: string,
    body: IShop.ShopForm
  ): Promise<IShop.PostResponseShop> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/shops/${id}`;
        const response = await this.http
          .patch<IShop.PostResponseShop>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteLocationShopById(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/shops/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // stat
  getStat(startDate, endDate) {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await this.http
          .get(
            this.API_URL +
            `/dashboard/organize/stats?start=${startDate}&end=${endDate}`
          )
          .toPromise();
        resolve(res);
      } catch (error) {
        log.debug("something wrong, from server.", error);
      }
    });
  }

  getDeviceStat(startDate, endDate) {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await this.http
          .get(
            this.API_URL +
            `/dashboard/device/stats?start=${startDate}&end=${endDate}`
          )
          .toPromise();
        resolve(res);
      } catch (error) {
        log.debug("something wrong, from server.", error);
      }
    });
  }

  // insight
  getVisitorDensity(
    queryParams: IDensitiesParam
  ): Promise<IDashboard.Densities[]> {
    const { start, end, timezone = "Asia/Bangkok" , devices } = queryParams;
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${this.API_URL}/dashboard/organize/densities/?start=${start}&end=${end}&timezone=${timezone}`;
        if(devices && devices !== '')  url += `&devices=${devices}`
        const response = await this.http
          .get<IDashboard.Densities[]>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getTotalCustomer(): Promise<{ totalCustomer: number }> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/customers/total`;
        const response = await this.http
          .get<{ totalCustomer: number }>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // message template
  createMessageTemplate(body: PostPayload): Promise<Message> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/message/templates`;
        const response = await this.http.post<Message>(url, body).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  editMessageTemplateById(id: string, body: PostPayload): Promise<Message> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/message/templates/${id}`;
        const response = await this.http.patch<Message>(url, body).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getMessageTemplate(queryParams: IQueryParams): Promise<GetResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/message/templates${_queryParams}`;
        const response = await this.http.get<GetResponse>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getMessageTemplateById(id: string): Promise<Message> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/message/templates/${id}`;
        const response = await this.http.get<Message>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteMessageTemplate(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/message/templates/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getUserGroups(queryParams: IQueryParams): Promise<ListResponse> {
    const { limit, page } = queryParams;
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/groups?limit=${limit}&page=${page}`;
        const response = await this.http.get<ListResponse>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // message template
  uploadFile(body: UploadFilePayload): Promise<UploadFileResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/internal/upload-image/upload-base64`;
        const response = await this.http
          .post<UploadFileResponse>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // =====  Coupon PART  =====
  createCoupon(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/coupon`;
        const response = await this.http.post(url, payload).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCoupons(queryParams) {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/coupon${_queryParams}`;
        const response = await this.http.get(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCouponById(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/coupon/${id}`;
        const response = await this.http.get(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateCoupon(id, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/coupon/${id}`;
        const response = await this.http.patch(url, payload).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteCouponById(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/coupon/${id}`;
        const response = await this.http.delete(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCouponRedeemInfos(id, page?, limit?) {
    let params = new HttpParams();
    if (page && limit) {
      params = params.set('page', String(page))
      params = params.set('limit', String(limit));
    }
    return new Promise(async (resolve, reject) => {

      try {
        const url = `${this.API_URL}/coupon/getRedeemInfos/${id}`;
        const response = await this.http.get(url, { params }).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCouponInfos() {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/coupon/getInfos`;
        const response = await this.http.get(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }


  deleteCouponExternalCode(couponId,selectedCode) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/couponExternalCode/code/${couponId}/${selectedCode}`;
        const response = await this.http.delete(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  sendCoupon(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/coupon/send`;
        const response = await this.http.post(url,payload).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  couponExternalCodeImport(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/couponExternalCode/import`;
        const response = await this.http.post(url,payload).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  

  // =====  PROFILE PART  =====
  getProfile() {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/users/profile`;
        const response = await this.http.get(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateProfile(body) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/users/profile/`;
        const response = await this.http.patch(url, body).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // =====  Organization PART  =====
  getOrganize(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/organizes/${id}`;
        const response = await this.http.get(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateOrganize(id, body) {
    log.debug("updateOrganize id => %o", id)
    log.debug("updateOrganize body => %o", body)

    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/organizes/${id}`;
        log.debug("updateOrganize url => %o", url)

        const response = await this.http.patch(url, body).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // =====  Device Logs PART  =====
  getDeviceLogs(deviceId, fromDate, toDate, limit = 10, page = 1) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/devices/logs/${deviceId}?from=${fromDate}&to=${toDate}&limit=${limit}&page=${page}`;
        const response = await this.http.get(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // ==== Campaign PART ====

  getCustomerActivitiesByCampaign(
    customerId,
    customerGroupId,
    campaignId,
    fromDate,
    toDate,
    limit = 10,
    page = 1
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${this.API_URL}/admin/customers/activities?campaignId=${campaignId}&from=${fromDate}&to=${toDate}&limit=${limit}&page=${page}`;

        if (customerId) url += `&customerId=${customerId}`
        if (customerGroupId) url += `&customerGroupId=${customerGroupId}`

        log.debug("url => %o", url)

        const response = await this.http.get(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // campaign

  createCampaign(body: ICampaign.Payload): Promise<ICampaign.Campaign> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/campaign`;
        const response = await this.http
          .post<ICampaign.Campaign>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCampaigns(queryParams: IQueryParams): Promise<ICampaign.GetResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/campaign${_queryParams}`;
        const response = await this.http
          .get<ICampaign.GetResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCampaignById(id: string): Promise<ICampaign.Campaign> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/campaign/${id}`;
        const response = await this.http
          .get<ICampaign.Campaign>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCampaignStatById(
    id: string,
    queryParams?: IQueryParams
  ): Promise<ICampaign.CampaignStat[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const _queryParams = queryParamsToUrl(queryParams);
        const url = `${this.API_URL}/campaign/stat/${id}${_queryParams}`;
        const response = await this.http
          .get<ICampaign.CampaignStat[]>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getCustomerLatestStateByCampaignId(
    id: string,
    queryParams?: IQueryParams
  ): Promise<ICampaign.CustomerStateResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/campaign/latest/state/${id}${_queryParams}`;
        const response = await this.http
          .get<ICampaign.CustomerStateResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateCampaignById(
    id: string,
    body: ICampaign.Payload
  ): Promise<ICampaign.Campaign> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/campaign/${id}`;
        const response = await this.http
          .patch<ICampaign.Campaign>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteCampaign(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/campaign/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getTotalDepartmentByFloorId(
    floorId: string
  ): Promise<{ totalCount: number }> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/floors/total/department/${floorId}`;
        const response = await this.http
          .get<{ totalCount: number }>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getTotalShopByDepartmentId(
    departmentId: string
  ): Promise<{ totalCount: number }> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/location/departments/total/shop/${departmentId}`;
        const response = await this.http
          .get<{ totalCount: number }>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // Questionnaire
  createQuestionnaire(
    body: IQuestionnaire.QuestionFormValue
  ): Promise<IQuestionnaire.QuestionnaireResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/questionnaire`;
        const response = await this.http
          .post<IQuestionnaire.QuestionnaireResult>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getQuestionnaire(
    queryParams: IQueryParams
  ): Promise<IQuestionnaire.ListResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/questionnaire${_queryParams}`;
        const response = await this.http
          .get<IQuestionnaire.ListResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getQuestionnaireById(
    id: string
  ): Promise<IQuestionnaire.QuestionnaireResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/questionnaire/${id}`;
        const response = await this.http
          .get<IQuestionnaire.QuestionnaireResult>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateQuestionnaireById(
    id: string,
    body: IQuestionnaire.QuestionFormValue
  ): Promise<IQuestionnaire.QuestionnaireResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/questionnaire/${id}`;
        const response = await this.http
          .patch<IQuestionnaire.QuestionnaireResult>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteQuestionnaireById(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/questionnaire/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  // Broadcast
  createBroadcast(
    body: IBroadcast.BroadcastFormValue
  ): Promise<IBroadcast.BroadcastResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/broadcast`;
        const response = await this.http
          .post<IBroadcast.BroadcastResult>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getBroadcast(queryParams: IQueryParams): Promise<IBroadcast.ListResponse> {
    const _queryParams = queryParamsToUrl(queryParams);
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/broadcast${_queryParams}`;
        const response = await this.http
          .get<IBroadcast.ListResponse>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getBroadcastById(id: string): Promise<IBroadcast.BroadcastResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/broadcast/${id}`;
        const response = await this.http
          .get<IBroadcast.BroadcastResult>(url)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  updateBroadcastById(
    id: string,
    body: IBroadcast.BroadcastFormValue
  ): Promise<IBroadcast.BroadcastResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/broadcast/${id}`;
        const response = await this.http
          .patch<IBroadcast.BroadcastResult>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  deleteBroadcastById(id: string): Promise<null> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/broadcast/${id}`;
        const response = await this.http.delete<null>(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getQuestion() {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/questionnaire/condition`;
        const response = await this.http.get(url).toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

  getAnswersQuestionById(
     id: string,
     queryParams?: IQueryParams
   ): Promise<ICampaign.CampaignStat[]> {
     return new Promise(async (resolve, reject) => {
       try {
         const _queryParams = queryParamsToUrl(queryParams);
         const url = `${this.API_URL}/question/${id}${_queryParams}`;
         const response = await this.http
           .get<any>(url)
           .toPromise();
         resolve(response);
       } catch (error) {
        resolve(null);
       }
     });
   }
   
  calculateCustomerGroup(
    body: ICustomerGroup.CustomerGroupForm
  ): Promise<ICustomerGroup.PostResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.API_URL}/admin/groups/calculateCustomers`;
        const response = await this.http
          .post<ICustomerGroup.PostResponse>(url, body)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug("something wrong, from server.", error);
        reject(error);
      }
    });
  }

}
