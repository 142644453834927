import { ObjectValueType } from "./type.util";

export const StartUpConditionType = {
  Enter: "enter",
  Stay: "stay",
  Both: "both",
  Leave: "leave",
} as const;
export type StartUpConditionType = ObjectValueType<typeof StartUpConditionType>;

export const ChartElementType = {
  Start: "start",
  Message: "message",
  Condition: "condition",
  AddCondition: "add-condition",
} as const;
export type ChartElementType = ObjectValueType<typeof ChartElementType>;

export const CampaignRepeatType = {
  Always: "Always",
  SpecificTime: "Specific Time",
} as const;
export type CampaignRepeatType = ObjectValueType<typeof CampaignRepeatType>;

export const ReceivngFrequencyType = {
  Every10Mins: { label: "Every 10 minutes", value: "10m" },
  Every30Mins: { label: "Every 30 minutes", value: "30m" },
  Every1Hour: { label: "Every a hour", value: "1h" },
  Every6Hours: { label: "Every 6 hours", value: "6h" },
  OnceADay: { label: "Once a day", value: "1d" },
  Every3Days: { label: "Every 3 days", value: "3d" },
  OnceAWeek: { label: "Once a week", value: "1w" },
  OnceAMonth: { label: "Once a month", value: "1M" },
  OnceACampaign: { label: "Once a campaign", value: "once" },
} as const;
export type ReceivngFrequencyType = ObjectValueType<
  typeof ReceivngFrequencyType
>;

export const ConditionStepType = {
  Gender: "Gender",
  Age: "Age",
  Questionnaire: "Questionnaire",
} as const;
export type ConditionStepType = ObjectValueType<typeof ConditionStepType>;

export const ConditionGenderType = {
  male: "male",
  female: "female",
} as const;
export type ConditionGenderType = ObjectValueType<typeof ConditionGenderType>;

export const ConditionAgeType = {
  gt20lt24: { label: "20-24 Years old", value: '{"$gte":20, "$lte":24}' },
  gt25lt29: { label: "25-29 Years old", value: '{"$gte":25, "$lte":29}' },
  gt30lt34: { label: "30-34 Years old", value: '{"$gte":30, "$lte":34}' },
  gt35lt39: { label: "35-39 Years old", value: '{"$gte":35, "$lte":39}' },
  gt40lt44: { label: "40-44 Years old", value: '{"$gte":40, "$lte":44}' },
  gt45lt49: { label: "45-49 Years old", value: '{"$gte":45, "$lte":49}' },
  gt50: { label: "50 years or older", value: '{"$gt":50}' },
} as const;
export type ConditionAgeType = ObjectValueType<typeof ConditionAgeType>;

export const CustomerStateType = {
  Total: "total",
  Idle: "idle",
  Enter: "enter",
  Send: "send",
  Clicked: "clicked",
} as const;
export type CustomerStateType = ObjectValueType<typeof CustomerStateType>;
