import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetLanguageService {
  selectedLanguage:string;

  private _selectedLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('selectedLanguage') || 'en');
  selectedLanguage$: Observable<string> = this._selectedLanguage.asObservable();

  constructor() { }

  updateSelectedLanguage(newLanguage: string): void {
    this._selectedLanguage.next(newLanguage);
  }
}
