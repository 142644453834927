import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TreeNode } from 'primeng/api';
import { BackendService } from "app/services/backend.service";
import { ItemOption } from '@core/components/linx-input/select';
import { DepartmentOption, OptionsService } from 'app/services/options.service';


@Injectable({
  providedIn: 'root'
})
export class NodeService {
  shopOptions: ItemOption[];
  floorOptions: ItemOption[] = [];
  departmentOptions: DepartmentOption[] = [];



  constructor(
    private http: HttpClient,
    private _backendService: BackendService,
    private _optionsService: OptionsService,
    ) { }

    async getFiles() {
      await this.getAllFloorData();
    
      const floor: TreeNode[] = this.createTreeNodes(this.floorOptions, this.departmentOptions, this.shopOptions);
      return floor;
    }
    
    createTreeNodes(floor: any[], department: any[], shop: any[]): TreeNode[] {
      return floor.map((floorNode) => {
        const departmentNodes = department
          .filter((deptNode) => deptNode.floor === floorNode.value)
          .map((deptNode) => {
            const shopNodes = shop
              .filter((shopNode) => shopNode.parent.value === deptNode.value)
              .map((shopNode) => {
                return {
                  label: shopNode.label,
                  data: shopNode.value,
                  type: "shop",
                  collapsedIcon: "pi pi-caret-right",
                  parent: {
                    label: deptNode.label,
                    data: deptNode.value,
                    type: "department",
                    expandedIcon: "pi pi-folder-open",
                    collapsedIcon: "pi pi-folder",
                    partialSelected: true,
                    expanded: true,
                    parent: {
                      label: floorNode.label,
                      data: floorNode.value,
                      type: "floor",
                      expandedIcon: "pi pi-folder-open",
                      collapsedIcon: "pi pi-folder",
                      partialSelected: true,
                      expanded: true
                    }
                  },
                };
              });

            return {
              label: deptNode.label,
              data: deptNode.value,
              type: "department",
              expandedIcon: "pi pi-folder-open",
              collapsedIcon: "pi pi-folder",
              children: shopNodes,
              class: 'chip-disabled',
              parent: {
                label: floorNode.label,
                data: floorNode.value,
                type: "floor",
                expandedIcon: "pi pi-folder-open",
                collapsedIcon: "pi pi-folder",
                partialSelected: true,
                expanded: true,
              }
            };
          });
    
        return {
          label: floorNode.label,
          data: floorNode.value,
          type: "floor",
          class: 'chip-disabled',
          expandedIcon: "pi pi-folder-open",
          collapsedIcon: "pi pi-folder",
          children: departmentNodes,
        };
      });
    }

  async getAllFloorData() {
    this.floorOptions = await this._optionsService.getLocationFloorOptions();
    this.departmentOptions = await this._optionsService.getLocationDepartmentOptions();
    this.shopOptions = await this._optionsService.getLocationShopOptions();
  }

}
