export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://api.blinx.linxlab.io/v1',
  sentry: {
    enable: true,
    environment: 'production',
    dsn: 'https://9e98c54529d244e6944f2af3be8be047@sentry.rawinlab.com/9',
    tracesSampleRate: 0.1, // only 10% of request
  },
};
