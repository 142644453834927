import { NgModule } from "@angular/core";

import { FilterPipe } from "@core/pipes/filter.pipe";

import { InitialsPipe } from "@core/pipes/initials.pipe";
import { DatePipe } from '@angular/common';

import { SafePipe } from "@core/pipes/safe.pipe";
import { StripHtmlPipe } from "@core/pipes/stripHtml.pipe";
import { IsContainPipe } from "./isContain.pipe";
import { UnixTimestampPipe } from "./unix-timestamp.pipe";
import { DisplayNamePipe } from './display-name.pipe';
@NgModule({
  declarations: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    IsContainPipe,
    UnixTimestampPipe,
    DisplayNamePipe,
  ],
  providers: [DatePipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, IsContainPipe, UnixTimestampPipe, DisplayNamePipe],
})
export class CorePipesModule { }
